import React from "react";
import AboutOld from "./components/Layout/AboutOld/About";
import Rpa from "./components/Layout/RpaPage/Rpa";
import Mobile from "./components/Layout/MobilePage/Mobile";
import Home from "./components/Layout/Home/Home";
import Cookies from "./components/Layout/Cookies/Cookies";
import PrivacyPolicy from "./components/Layout/PrivacyPolicy/Privacy"
import Newsletter from "./components/Layout/NewsletterStatue/NewsletterStatue"
import Unsubscribe from "./components/Layout/Unsubscribe/Unsubscribe"
import Customers from "./components/Layout/Customers/Customers"
import NonExistentPage from "./components/NoPageFound"
import OpinionRegulations from "./components/Layout/OpinionRegulations/OpinionRegulations"
import AutomationBusiness from "./components/Layout/AutomationBusiness/AutomationBusiness"
import AutomationProduction from "./components/Layout/AutomationProduction/AutomationProduction"
import AutomationLogistics from "./components/Layout/AutomationLogistics/AutomationLogistics"

const routes = {

  "/": () => <Home />,
  "/about": () => <AboutOld/>,
  "/rpa": () => <Rpa/>,
  "/mobile": () => <Mobile/>,
  "/customers": () => <Customers/>,
  "/cookies": () => <Cookies />,
  "/privacy": () => <PrivacyPolicy />,
  "/newsletter-regulations": () => <Newsletter />,
  "/opinion-regulations": () => <OpinionRegulations />,
  "/unsubscribe": () => <Unsubscribe />,
  "/business-automation": () => <AutomationBusiness />,
  "/production-automation": () => <AutomationProduction />,
  "/logistics-automation": () => <AutomationLogistics />,
  "/**": () => <NonExistentPage />
};

export default routes;
