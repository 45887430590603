import React from 'react';
import ProgressLine from "../Home/ProgressLine";
import counter1 from '../../../assets/icons/counter1.svg';
import counter2 from '../../../assets/icons/counter2.svg';
import counter3 from '../../../assets/icons/counter3.svg';
import counter4 from '../../../assets/icons/counter4.svg';
import processAutomation from '../../../assets/icons/process-automation.svg';
import processImprove from '../../../assets/icons/process-improve.svg';
import processObjective from '../../../assets/icons/process-objective.svg';
import processCommunication from '../../../assets/icons/process-communication.svg';
import processClarity from '../../../assets/icons/process-clarity.svg';
import UIbg from '../../../assets/Uibg1.jpg';
import {useSelector} from 'react-redux';
import { Helmet } from "react-helmet";
import { Spring } from "react-spring/renderprops";
import TrackVisibility from 'react-on-screen';

const AutomationBusiness = () => {
  const languageData = useSelector(state => state.languageReducer.data);

  const AutomationTrack1 = ({ isVisible }) => {
    const style = {
        opacity : isVisible ? '1' : '0',
        transform: isVisible ? "translateX(0)" : "translateX(-150%)",
        transition: ".75s ease",
    };
    return <div className="txt-sm txt-blue txt-container" style={style}><b>Automatyzacja procesów biznesowych</b> może być zastosowana dosłownie do każdego rodzaju procesu biznesowego. 
    Mogą być to zarówno proste zadania np. wprowadzanie danych lub zarządzanie dokumentami, jak i bardziej złożone np. realizacja zamówień, 
    obsługa klienta i wiele innych. Dzięki automatyzacji Państwa firma poprawi swoją wydajność, liczba błędów znacznie się zmniejszy,
    a pracownicy będą mogli się oddawać zadaniom o większej wartości dodanej. Wśród korzyści BPA należy wymienić także: znaczne obniżenie kosztów, 
    wzrost efektywności pracy, standaryzację procesów w przedsiębiorstwie, wzrost możliwości kontroli oraz poprawę cyberbezpieczeństwa.</div>;
  }
  
  const AutomationTrack2 = ({ isVisible }) => {
    const style = {
        opacity : isVisible ? '1' : '0',
        transform: isVisible ? "translateX(0)" : "translateX(150%)",
        transition: ".75s ease",
    };
    return <div className="txt-sm txt-blue txt-container" style={style}>Nie można też nie wspomnieć o takich istotnych aspektach jak spotęgowanie kultury innowacyjności oraz unowocześnienie organizacji. 
    <b> Robotyzacja procesów biznesowych </b>zwiększy również konkurencyjność oraz poprawi skalowalność, bezbłędność i szybkość działań. Należy jedynie pamiętać, że nie każdy proces można zautomatyzować. 
    Efektywne wdrożenie będzie dotyczyło tylko niektórych działań, spełniających odpowiednie uwarunkowania.</div>;
  }
  
  const AutomationTrack3 = ({ isVisible }) => {
    const style = {
        opacity : isVisible ? '1' : '0',
        transform: isVisible ? "translateX(0)" : "translateX(-150%)",
        transition: ".75s ease",
    };
    return <div className="txt-sm txt-blue txt-container" style={style}><b>Automatyzacja procesów biznesowych</b> może być wykorzystywana w różnych obszarach, 
    których wspólnym mianownikiem jest poprawa wydajności i produktywności danego przedsiębiorstwa. 
    Wśród najpopularniejszych przykładów należy wymienić m.in. automatyzację zadań manualnych, gdzie automatyzacji poddawane są powtarzalne,
    ręczne działania, obarczone wysokim prawdopodobieństwem wystąpienia błędu. BPA wykorzystywana jest także w usprawnieniu procesów,
    np. ma wpływ na skrócenie czasu ich przetwarzania. To z kolei może mieć znaczący wpływ na zmniejszenie czasu realizacji,
    co przekłada się na bezpośrednie zwiększenie satysfakcji kontrahentów.</div>;
  }
  
  const AutomationTrack4 = ({ isVisible }) => {
    const style = {
        opacity : isVisible ? '1' : '0',
        transform: isVisible ? "translateX(0)" : "translateX(150%)",
        transition: ".75s ease",
    };
    return <div className="txt-sm txt-blue txt-container" style={style}>Zadowolenie klientów może zagwarantować także szybsza
    i bardziej zorganizowana obsługa, do czego też może przyczynić się właściwa <b>robotyzacja procesów biznesowych</b>. 
    Warto wspomnieć również o poprawie dokładności i spójności, usprawnieniu komunikacji nie tylko między pracownikami 
    i kontrahentami, ale także wewnątrz działów oraz zwiększeniu przejrzystości. Zwłaszcza ostatni obszar jest szczególnie istotny, 
    gdyż umożliwia skuteczniejszą identyfikację tzw. wąskich gardeł oraz innych przestrzeni wymagających usprawnienia. 
    Na koniec należy zwrócić uwagę również na fakt, że <b>optymalizacja procesów biznesowych</b> oraz ich automatyzacja ma również 
    wpływ na późniejsze skalowanie biznesu, dzięki czemu obsługa większego wolumenu nie musi wiązać się koniecznością zatrudnienia większej liczby pracowników.</div>;
  }
  
  const AutomationTrack5 = ({ isVisible }) => {
    const style = {
        opacity: isVisible ? '1' : '0',
        transition: ".75s ease",
    };
    return <p className="txt-sm txt-blue w-75 el-center" style={style}>Jeżeli chcą Państwo wdrożyć <b>automatyzację procesów biznesowych</b> w Państwa firmie i zależy Państwu na kompleksowych, 
    dedykowanych rozwiązaniach, to serdecznie zapraszamy do współpracy. Warto rozpocząć rewolucję już dziś. Zachęcamy do kontaktu. 
    Automatyzacja procesów biznesowych (BPA) to nowy wymiar optymalizacji, dzięki któremu można oszczędzić czas i pieniądze. Zapraszamy do poznania naszych rozwiązań.</p>;
  }

  const AutomationSteps = ({ isVisible }) => {
    const style = {
        fontSize: isVisible ? "24px" : "16px",
        bottom: isVisible ? "-5px" : "-30px",
        opacity: isVisible ? '1' : '0',
    };
    const styleIcons = {
      opacity: isVisible ? '1' : '0',
      transition: ".75s ease",
    };
    return<div className="bussines-step-container mt-50">
          <div className="bussines-step bussines-step1">
            <p className="txt-blue txt-bold bussines-step-info" style={style}>Analiza</p>
            <img src={counter1} alt="counter1 icon" className="business-counter business-counter1 process-icon" style={styleIcons}/>
          </div>
          <div className="bussines-step bussines-step2">
            <p className="txt-blue txt-bold bussines-step-info" style={style}>Optymalizacja</p>
            <img src={counter2} alt="counter2 icon" className="business-counter business-counter2 process-icon" style={styleIcons}/>
          </div>
          <div className="bussines-step bussines-step3">
            <p className="txt-blue txt-bold bussines-step-info" style={style}>Modelowanie</p>
            <img src={counter3} alt="counter3 icon" className="business-counter business-counter3 process-icon" style={styleIcons}/>
          </div>
          <div className="bussines-step bussines-step4">
            <p className="txt-blue txt-bold bussines-step-info" style={style}>Wdrożenie</p>
            <img src={counter4} alt="counter4 icon" className="business-counter business-counter4 process-icon" style={styleIcons}/>
          </div>
        </div>;
  }


    return(
    <div>
    <Helmet>
        <title>Automyzacja procesów biznesowych - AmnisCode</title>
        <meta name="title" content="Automyzacja procesów biznesowych - AmnisCode"/>
        <meta name="description" content="Automatyzacja procesów biznesowych (BPA) to nowy wymiar optymalizacji, dzięki któremu można oszczędzić czas i pieniądze. Zapraszamy do poznania naszych rozwiązań."/>
        <link rel="canonical" href="https://amniscode.pl/business-automation"/>
        <meta name="robots" content="index, follow"/>
    </Helmet>
      <div className="business_cont1">
        <div className="page-title-content__wrapper">
            <div className="title-container">
              <h1 className="title">Automatyzacja procesów biznesowych</h1>
              <ProgressLine backgroundColor="#CACFD4" visualParts={[{ percentage: "50%", color: "#69afea" }]}/>
              <h3 className="subtitle">Uzyskaj pozycję lidera rynku</h3>
            </div>
        </div>
      </div>

      <div className="content__wrapper rpa_cont2">
        <h2 className="blue-bold__subtitle">Automatyzacja procesów biznesowych</h2>
        <div className="sm-blue-line"></div>
        <div className="d-flex-sp-btwn">
          <div className="txt-sm txt-container">Sukces firmy oraz jej wydajność to zasługa procesów, które zawierają całe know-how danego przedsiębiorstwa. 
          To właśnie procesy pozwalają uzyskać pozycję lidera rynku danej branży lub wypracować przewagę nad konkurencją.</div>
          <div className="txt-sm txt-container"><b>Automatyzacja procesów biznesowych</b> (BPA – Business Process Automation) to jedna z tych kwestii, która w dobie transformacji cyfrowej
           (swoistej nowej rewolucji przemysłowej) jest bardzo często poruszana. Automatyzacja nie tylko pozwala tworzyć nowe wartości biznesowe, ale również przynosi szereg korzyści.</div>
        </div>
        <div className="d-flex-sp-center">
          <a className="btn-orange mt-45" href="https://amniscode.pl/#kontakt">Porozmawiajmy o automatyzacji w Twojej firmie - napisz do nas</a>
          </div>
      </div>

      <div className="content__wrapper rpa_cont4">
        <h2 className="blue-bold__subtitle el-center">Czym jest automatyzacja procesów biznesowych?</h2>
        <div className="sm-blue-line el-center"></div>
          <div className="txt-sm txt-blue w-75 el-center">Według podstawowej definicji zadaniem automatyzacji jest zastąpienie czynności wykonywanej przez człowieka - 
          działaniem wykonywanym przez maszynę. Maszyna jest odporna na błędy ludzkie wynikające m.in. ze zmęczenia oraz zawsze dany proces wykonuje w ten sam sposób 
          (na podstawie zadanych wytycznych i zgodnie z projektem). W związku z tym <b>automatyzacja procesów biznesowych</b> to nic innego jak przeniesienie monotonnych, żmudnych,
          powtarzalnych zadań z pracownika na dedykowane oprogramowanie. Sam mechanizm jest odpowiedzią na zmiany wynikające z transformacji cyfrowej i musi być on przemyślany,
          właściwie zaprojektowany i starannie wdrożony w kulturę danego przedsiębiorstwa, by przyniósł najlepsze z możliwych korzyści. AmnisCode jest nieocenionym wsparciem w tym procesie,
          dzięki któremu Państwa firma utrzyma wysoką wydajność.</div>

          <TrackVisibility once offset={-5}>
          <AutomationSteps/>
        </TrackVisibility>
      </div>

      <div className="content__wrapper">
        <h2 className="blue-bold__subtitle el-center">Korzyści płynące z automatyzacji procesów biznesowych</h2>
        <div className="sm-blue-line el-center"></div>
        <div className="d-flex-sp-btwn automation_cont">
            <TrackVisibility once offset={150}>
              <AutomationTrack1/>
            </TrackVisibility>
            <TrackVisibility once offset={150}>
              <AutomationTrack2/> 
            </TrackVisibility>
        </div>
      </div>

      <div className="rpa_cont6">
        <div className="rpa-ask-for-contact__cont" style={{backgroundImage: `url(${UIbg})` }}>
          <h2 className="rpa-ask-for-contact__title">Gotowy do automatyzacji procesów biznesowych?</h2>
          <a className="btn-orange mt-45" href="https://amniscode.pl/#kontakt">{languageData.askForContact}</a>
        </div>
      </div>

      <div className="content__wrapper rpa_cont4">
        <h2 className="blue-bold__subtitle el-center">Analiza procesów biznesowych – dlaczego jest tak istotna?</h2>
        <div className="sm-blue-line el-center"></div>
        <div className="txt-sm txt-blue w-75 el-center"><b>Optymalizacja procesów biznesowych</b> jest pierwszym krokiem ku automatyzacji. Zanim jednak możliwe będzie zoptymalizowanie procesów, 
        należy zdać sobie sprawę z ich występowania. <b>Analiza procesów biznesowych</b> jest jednym z kluczowych elementów, by automatyzacja przyniosła wymierne efekty. 
        Każde działanie w organizacji może być zoptymalizowane oraz zaprojektowane na wiele różnych sposobów. Nawet jeżeli dany proces był stosowany przez lata, 
        to nie znaczy, że nie można go udoskonalić. Co więcej, powtarzanie wadliwych schematów, które następnie zostaną zautomatyzowane, 
        to ogromna strata nie tylko zasobów danego przedsiębiorstwa, ale przede wszystkim czasu, który mógłby być przeznaczony na istotniejsze działania. 
        Istnieje również obawa, że nieprawidłowa struktura mogłaby negatywnie wpłynąć na zaufanie do całego procesu oraz jego efektywność. 
        Wszystkie powyższe argumenty jasno pokazują, że <b>analiza procesów biznesowych</b> powinna być wykonana przed optymalizacją, modelowaniem 
        i późniejszym wdrażaniem automatyzacji, by całość przyniosła zamierzony i oczekiwany efekt.</div>

        <div className="d-flex-sp-center mt-50">
          <div className="info-box shdw-sm">
            <p className="txt-blue txt-bold txt-procces">Automatyzacja zadań manualnych</p>
            <img src={processAutomation} alt="analytics icon"/>
          </div>
          <div className="info-box shdw-sm">
            <p className="txt-blue txt-bold txt-procces">Usprawnianie procesów</p>
            <img src={processImprove} alt="ai icon"/>
          </div>
          <div className="info-box shdw-sm">
            <p className="txt-blue txt-bold txt-procces">Poprawa dokładności i spójności</p>
            <img src={processObjective} alt="network icon"/>
          </div>
          <div className="info-box shdw-sm">
            <p className="txt-blue txt-bold txt-procces">Usprawnienie komunikacji</p>
            <img src={processCommunication} alt="ai icon"/>
          </div>
          <div className="info-box shdw-sm">
            <p className="txt-blue txt-bold txt-procces">Zwiększenie przejrzystości</p>
            <img src={processClarity} alt="ai icon"/>
          </div>
        </div>
      </div>

      <div className="content__wrapper rpa_cont2 rpa_cont4">
        <h2 className="blue-bold__subtitle el-center">Automatyzacja procesów biznesowych - w jakich obszarach ją wykorzystać?</h2>
        <div className="sm-blue-line el-center"></div>
        <div className="d-flex-sp-btwn automation_cont">
            <TrackVisibility once offset={150}>
              <AutomationTrack3/>
            </TrackVisibility>
            <TrackVisibility once offset={150}>
              <AutomationTrack4/>
            </TrackVisibility>
        </div>
      </div>

      <div className="content__wrapper rpa_cont4">
        <h2 className="blue-bold__subtitle el-center">Automatyzacja procesów biznesowych szybkim sposobem na zwiększenie ich produktywności</h2>
        <div className="sm-blue-line el-center"></div>
        <div className="txt-sm txt-blue w-75 el-center">Jeżeli celem jest szybka poprawa wyników organizacji i to bez konieczności stosowania dużych zasobów i radykalnych
        zmian to <b>automatyzacja procesów biznesowych</b> będzie najlepszym sposobem. Warto podkreślić, że właściwe wdrożenie pozwoli na o wiele skuteczniejsze wykorzystanie 
        zasobów ludzkich, gdyż pracownicy będą mogli całą swoją uwagę skoncentrować na zadaniach kreatywnych, koncepcyjnych, podczas gdy powtarzalne, monotonne i mało
        produktywne działania zostaną powierzone automatom. <b>Optymalizacja procesów</b> biznesowych oraz ich późniejsza automatyzacja pozwoli na skrócenie czasu ich wykonywania,
        co będzie miało wpływ na zwiększenie produktywności całego przedsiębiorstwa.</div>
        <TrackVisibility once>
          <AutomationTrack5/>
        </TrackVisibility>
      </div>
  </div>
  )
}

export default AutomationBusiness;