import React from 'react';
import {useSelector} from 'react-redux';
import { Spring } from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";
import { A } from "hookrouter";
const AboutSection = (props) => {

    const languageData = useSelector(state => state.languageReducer.data);
    function goAbout() {
      setTimeout(() => {
      document.getElementById('about').scrollIntoView({behavior: 'smooth'});
    }, 500);
    }
    

    return(
        <div id="aboutUs" className="aboutUs__cont">
          <div className="about__cont--change">
            <div className="aboutUs__cont--txt">
              <h2 className="aboutUs__cont--title" style={{ ...props }}>{languageData.About}</h2>
              <div>
                <p className="aboutUs__cont--description">{languageData.AmniseDescription1}</p>
                <p className="aboutUs__cont--description">{languageData.AmniseDescription2}</p>
              </div>
              <A href="/about" type="submit" className="contact__const--btn" onClick={goAbout}>{languageData.MoreInfo}</A>
            </div>
          </div>
        </div>
    )
}

export default AboutSection;

