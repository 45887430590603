import React from 'react';
import ProgressLine from "../Home/ProgressLine";
import robotic from '../../../assets/icons/robotic.png';
import AI from '../../../assets/icons/AI.png';
import printer from '../../../assets/icons/3d-printer.png';
import processWarehouse from '../../../assets/icons/process-warehouse.svg';
import processPerformance from '../../../assets/icons/process-performance.svg';
import processEfficiency from '../../../assets/icons/process-efficiency.svg';
import processCustomer from '../../../assets/icons/process-customer.svg';
import processSaving from '../../../assets/icons/process-saving.svg';
import UIbg from '../../../assets/Uibg1.jpg';
import {useSelector} from 'react-redux';
import { Helmet } from "react-helmet";
import TrackVisibility from 'react-on-screen';

const AutomationLogistics = () => {
  const languageData = useSelector(state => state.languageReducer.data);

  const AutomationTrack1 = ({ isVisible }) => {
    const style = {
        opacity : isVisible ? '1' : '0',
        transform: isVisible ? "translateX(0)" : "translateX(-150%)",
        transition: ".75s ease",
    };
    return <div className="txt-sm txt-blue txt-container" style={style}><b>Automatyzacja procesów logistycznych</b> jest ważna z wielu powodów. Warto wymienić m.in. 
    zwiększenie wydajności, obniżenie kosztów i poprawę dokładności. Automatyzacja może pomóc w usprawnieniu procesów. Może to być m.in. 
    zarządzanie zapasami, realizacja zamówień i zarządzanie transportem. Dodatkowo ma ona wyraźny wpływ na redukcję kosztów poprzez np. 
    zmniejszenie liczby zatrudnionych osób, czego nie można osiągnąć w tradycyjnych operacjach logistycznych. Automatyzacja umożliwia również 
    przedsiębiorstwom lepsze zarządzanie łańcuchami dostaw poprzez poprawę widoczności i kontroli nad dostawami, produkcją i operacjami magazynowymi.
     To z kolei ma wpływ na znaczną poprawę obsługi klienta i wyższy poziom jego zadowolenia. Nie trzeba nikogo przekonywać, że satysfakcja kontrahentów jest istotna dla każdej działalności. </div>;
  }
  
  const AutomationTrack2 = ({ isVisible }) => {
    const style = {
        opacity : isVisible ? '1' : '0',
        transform: isVisible ? "translateX(0)" : "translateX(150%)",
        transition: ".75s ease",
    };
    return <div className="txt-sm txt-blue txt-container" style={style}><b>Automatyzacja procesów logistycznych</b> pomaga również organizacjom w bardziej elastycznym 
    reagowaniu na zmiany popytu, sprzyjając im jednocześnie zachować konkurencyjność na rynku. Tego typu działanie może być także istotnym
     wsparciem w identyfikacji obszarów wymagających poprawy w sieci łańcucha dostaw oraz zapewnieniu efektywnego wykorzystania zasobów. 
     W skrócie, automatyzacja procesów logistycznych jest tak ważna, gdyż może pomóc przedsiębiorstwom w podejmowaniu lepszych 
     i trafniejszych decyzji przy jednoczesnym zwiększeniu efektywności operacyjnej.</div>;
  }
  
  const AutomationTrack3 = ({ isVisible }) => {
    const style = {
        opacity : isVisible ? '1' : '0',
        transform: isVisible ? "translateX(0)" : "translateX(-150%)",
        transition: ".75s ease",
    };
    return <div className="txt-sm txt-blue txt-container" style={style}><b>Automatyzacja procesów logistycznych</b> umożliwia uzyskanie i utrzymanie stabilności, 
    co jest niezwykle istotne w niepewnych czasach. Dzieje się tak za sprawą: gwarancji ciągłości pracy, na co pozwala wykorzystanie robotów 
    oraz znaczne zmniejszenie kosztów, co jest możliwe dzięki właściwemu zarządzaniu zasobami firmy. Za przewagą konkurencyjną przemawia sprawna
     i szybka obsługa zamówień, płynna dostawa, wykwalifikowana obsługa klienta oraz ograniczenie kosztów obsługi procesów.</div>;
  }
  
  const AutomationTrack4 = ({ isVisible }) => {
    const style = {
        opacity : isVisible ? '1' : '0',
        transform: isVisible ? "translateX(0)" : "translateX(150%)",
        transition: ".75s ease",
    };
    return <div className="txt-sm txt-blue txt-container" style={style}>W dzisiejszych czasach, gdy niepewność rynkowa jest największa od lat, 
    automatyzacja może być kluczem nie tylko do osiągnięcia sukcesu, ale także utrzymania działalności danej firmy. 
    W ten sposób można zminimalizować wpływ czynników zewnętrznych na główne procesy (np. braki kadrowe), ulepszyć planowanie
     i zarządzanie dostępnymi zasobami oraz zoptymalizować wszelkie koszty.</div>;
  }
  
  const AutomationTrack5 = ({ isVisible }) => {
    const style = {
        opacity: isVisible ? '1' : '0',
        transition: ".75s ease",
        textAlign: "center"

    };
    return <p className="txt-sm txt-blue w-75 el-center" style={style}>Jeżeli chcą Państwo wdrożyć <b>automatyzację procesów 
    logistycznych</b> w Państwa firmie i cenią sobie Państwo kompleksowe, dedykowane rozwiązania, to serdecznie zapraszamy do współpracy. 
    Warto rozpocząć budowę przewagi konkurencyjnej już dziś. Automatyzacja procesów logistycznych to nowy wymiar optymalizacji, 
    dzięki któremu można zyskać przewagę na rynku. Zapraszamy do skorzystania z naszych rozwiązań.</p>;
  }

  const AutomationTechnologies = ({ isVisible }) => {
    const style = {
        fontSize: isVisible ? "18px" : "0",
        opacity: isVisible ? '1' : '0',
        transition: ".75s ease .25s",
    };
    const styleBox = {
      height: isVisible ? '200px' : '0',
      transition: "height .75s ease, transform .2s ease-out",
  };
    const styleIcons = {
      opacity: isVisible ? '1' : '0',
      transition: ".75s ease .5s",
    };
    return<div className="logistics-technology-container mt-50">
          <div className="logistics-technology" style={styleBox}>
            <p className="txt-blue txt-bold logistics-technology-info" style={style}>Robotyka</p>
            <img src={robotic} alt="counter1 icon" className="process-icon" style={styleIcons}/>
          </div>
          <div className="logistics-technology" style={styleBox}>
            <p className="txt-blue txt-bold logistics-technology-info" style={style}>Oprogramowanie AI</p>
            <img src={AI} alt="counter2 icon" className="process-icon" style={styleIcons}/>
          </div>
          <div className="logistics-technology" style={styleBox}>
            <p className="txt-blue txt-bold logistics-technology-info" style={style}>Druk 3D</p>
            <img src={printer} alt="counter3 icon" className="process-icon" style={styleIcons}/>
          </div>
        </div>;
  }

  return(
    <div>
    <Helmet>
        <title>Automatyzacja procesów logistycznych - AmnisCode</title>
        <meta name="title" content="Automatyzacja procesów logistycznych - AmnisCode"/>
        <meta name="description" content="Automatyzacja procesów logistycznych to nowy wymiar optymalizacji, dzięki któremu można zyskać przewagę na rynku. Zapraszamy do skorzystania z naszych rozwiązań."/>
        <link rel="canonical" href="https://amniscode.pl/logistics-automation"/>
        <meta name="robots" content="index, follow"/>
    </Helmet>
      <div className="logistics_cont1">
        <div className="page-title-content__wrapper">
            <div className="title-container">
              <h1 className="title">Automatyzacja procesów logistycznych</h1>
              <ProgressLine backgroundColor="#CACFD4" visualParts={[{ percentage: "50%", color: "#69afea" }]}/>
              <h3 className="subtitle">Nowy wymiar optymalizacji</h3>
            </div>
        </div>
      </div>

      <div className="content__wrapper rpa_cont2">
        <h2 className="blue-bold__subtitle">Automatyzacja procesów logistycznych</h2>
        <div className="sm-blue-line"></div>
        <div className="d-flex-sp-btwn">
            <div className="txt-sm txt-container">Stale rosnące znaczenie handlu elektronicznego oraz zmiany nawyków konsumentów sprawiają, 
            że przedsiębiorstwa muszą sprostać nowym wyzwaniom logistycznym. Warto wymienić w tym miejscu m.in. wysyłkę w ciągu doby lub sprawne zarządzanie zwrotami. 
            Tylko wydajna i efektywna logistyka, czyli skoncentrowana na wyeliminowaniu pomyłek, optymalizowaniu kosztów i udoskonalaniu procesów, 
            jest w stanie zapewnić przewagę konkurencyjną. <b>Automatyzacja procesów logistycznych</b>, czyli zamiana manualnej obsługi zrobotyzowanymi 
            urządzeniami i systemami zarządzania będzie doskonałym wsparciem w tym działaniu.</div>
            <div className="txt-sm txt-container">Rokrocznie liczba przedsiębiorstw z powodzeniem wykorzystujących nowoczesne rozwiązania technologiczne rośnie. 
            Co więcej, w najbliższych latach wykorzystanie <b>automatyzacji procesów logistycznych</b> będzie stale zyskiwało na popularności, 
            gdyż gwarantuje ona przedsiębiorstwom efektywny sposób zarządzania procesami. Warto nie pozostać w tyle.</div>
            </div>
        <div className="d-flex-sp-center">
          <a className="btn-orange mt-45" href="https://amniscode.pl/#kontakt">Porozmawiajmy o automatyzacji w Twojej firmie - napisz do nas</a>
          </div>
      </div>

      <div className="content__wrapper rpa_cont4">
        <h2 className="blue-bold__subtitle el-center">Czym jest automatyzacja procesów logistycznych?</h2>
        <div className="sm-blue-line el-center"></div>
            <div className="txt-sm txt-blue w-75 el-center"><b>Automatyzacja procesów logistycznych</b> to wykorzystanie technologii automatyzacji do usprawnienia 
            i optymalizacji procesów łańcucha dostaw: od zamawiania towarów po zarządzanie magazynem. Dzięki automatyzacji procesów logistycznych 
            przedsiębiorstwa mogą zaoszczędzić czas i pieniądze, jednocześnie zwiększając swoją efektywność i wydajność. Systemy automatyzacji, 
            takie jak robotyka, oprogramowanie AI, druk 3D i inne technologie, są wykorzystywane w magazynach, aby pomóc w zarządzaniu zapasami, 
            ich załadunku oraz przygotowywaniu zamówień do realizacji. Ponadto w najbliższych latach systemy zautomatyzowane będą wykorzystywane 
            również w celu poprawy widoczności łańcucha dostaw i umożliwienia podejmowania decyzji we właściwym czasie.</div>
            <p className="txt-sm txt-blue w-75 el-center"><b>Automatyzacja procesów logistycznych</b> pomaga m.in. ograniczyć błędy ludzkie 
            i zwiększyć dokładność w przetwarzaniu zamówień, wysyłce i zarządzaniu zapasami. W ten sposób możliwe jest także szybsze spełnienie 
            wymagań klientów, poprzez zapewnienie wglądu w operacje magazynowe w czasie rzeczywistym.</p>

            <TrackVisibility once >
              <AutomationTechnologies/>
            </TrackVisibility>
      </div>

      <div className="content__wrapper rpa_cont2">
        <h2 className="blue-bold__subtitle el-center">Automatyzacja procesów logistycznych – dlaczego jest tak ważna?</h2>
        <div className="sm-blue-line el-center"></div>
        <div className="d-flex-sp-btwn automation_cont">
            <TrackVisibility once offset={150}>
              <AutomationTrack1/>
            </TrackVisibility>
            <TrackVisibility once offset={150}>
              <AutomationTrack2/>
            </TrackVisibility>
        </div>
      </div>

      <div className="rpa_cont6">
        <div className="rpa-ask-for-contact__cont" style={{backgroundImage: `url(${UIbg})` }}>
          <h2 className="rpa-ask-for-contact__title">Gotowy do automatyzacji procesów logistycznych?</h2>
          <a className="btn-orange mt-45" href="https://amniscode.pl/#kontakt">{languageData.askForContact}</a>
        </div>
      </div>

      <div className="content__wrapper rpa_cont4">
        <h2 className="blue-bold__subtitle el-center">Korzyści płynące z automatyzacji procesów logistycznych</h2>
        <div className="sm-blue-line el-center"></div>
        <div className="txt-sm txt-blue w-75 el-center"><b>Automatyzacja procesów logistycznych</b> niesie ze sobą wiele korzyści, 
        które mają wyraźny wpływ na poprawę efektywności i wydajności całego przedsiębiorstwa. Automatyzacja, poprzez wykorzystanie systemów IT, 
        może usprawnić funkcjonowanie magazynów. W ten sposób można zaoszczędzić nie tylko czas, ale również istotnie zmniejszyć liczbę błędów ludzkich. 
        Jako przykład można podać automatyzację w magazynie, która może obejmować np. automatyczne kompletowanie i załadunek oraz zautomatyzowane procesy wysyłki.
        Wszystko to sprawia, że magazyn może mieć o wiele większą wydajność np. poprzez lepsze wykorzystanie przestrzeni (mniejsza liczba osób potrzebna do jego obsługi) oraz wyższą dokładność zamówień.</div>
        <p className="txt-sm txt-blue w-75 el-center">Dzięki <b>automatyzacji procesów logistycznych</b>, przepływy pracy mogą być usprawnione, 
        z mniejszą ilością błędów popełnianych przy realizacji zamówień, dając jednocześnie miejsce na rozwój. Automatyzacja poprawia również obsługę klienta, 
        ponieważ zamówienia są realizowane szybciej i z mniejszą liczbą błędów dzięki mniejszej ilości pracy ręcznej zaangażowanej w proces. 
        Większa wydajność wynikająca z automatyzacji logistyki prowadzi również do oszczędności kosztów dla firm, co pomaga im zachować konkurencyjność.</p>

        <div className="d-flex-sp-center mt-50">
          <div className="info-box shdw-sm">
            <p className="txt-blue txt-bold txt-procces">Usprawnienie funkcjonowania magazynów</p>
            <img src={processWarehouse} alt="analytics icon"/>
          </div>
          <div className="info-box shdw-sm">
            <p className="txt-blue txt-bold txt-procces">Poprawa wydajności</p>
            <img src={processPerformance} alt="ai icon"/>
          </div>
          <div className="info-box shdw-sm">
            <p className="txt-blue txt-bold txt-procces">Zwiększenie efektywności</p>
            <img src={processEfficiency} alt="network icon"/>
          </div>
          <div className="info-box shdw-sm">
            <p className="txt-blue txt-bold txt-procces">Lepsza obsługa klienta</p>
            <img src={processCustomer} alt="ai icon"/>
          </div>
          <div className="info-box shdw-sm">
            <p className="txt-blue txt-bold txt-procces">Oszczędność czasu i kosztów firmy</p>
            <img src={processSaving} alt="ai icon"/>
          </div>
        </div>
      </div>

      <div className="content__wrapper rpa_cont2">
        <h2 className="blue-bold__subtitle el-center">Automatyzacja procesów logistycznych skutecznym sposobem na zbudowanie przewagi nad konkurencją</h2>
        <div className="sm-blue-line el-center"></div>
        <div className="d-flex-sp-btwn automation_cont">
            <TrackVisibility once offset={150}>
              <AutomationTrack3/>
            </TrackVisibility>
            <TrackVisibility once offset={150}>
              <AutomationTrack4/>
            </TrackVisibility>
        </div>
            <TrackVisibility once>
              <AutomationTrack5/>
            </TrackVisibility>
      </div>
  </div>
  )
}

export default AutomationLogistics;