import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import phoneIcon from '../../../assets/icons/smartphone1.png';
import laptopIcon from '../../../assets/icons/laptop1.png';
import integrationIcon from '../../../assets/icons/integration1.png';
import aiBackground from '../../../assets/aiBg1.png';


const FifthSection = () => {

    const languageData = useSelector(state => state.languageReducer.data);
    const [inHover, setHover] = useState(false);
    const [inHover1, setHover1] = useState(false);
    const [inHover2, setHover2] = useState(false);
    const [inHover3, setHover3] = useState(false);
    const [inHover4, setHover4] = useState(false);
    const [inHover5, setHover5] = useState(false);
    


    
 
    return(
    
    <div id="oferta" className="home-offer__cont">
        <img src={aiBackground} alt="aiBackground" className="home-offer__bg-img"/>
        <div className="offer-wrapper">
            <h2 className="home-offer__title">oferta</h2>
            <p className="home-offer__tags">#Innowacyjność #Skuteczność #Profesjonalizm #NowoczesneTechnologie #Robotyzacja #SztucznaInteligencja #MachineLearning #ArtificialIntelligence #IntegracjeAPI</p>
            <div className="home-offer__box-cont">
                <a href="/mobile" className="offer-box">
                    <img src={phoneIcon} alt="phoneIcon"/>
                    <p className="box-title">aplikacje mobilne</p>
                </a>
                <a href="/rpa"className="offer-box">
                    <img src={laptopIcon} alt="laptopIcon"/>
                    <p className="box-title">ROBOTYZACJA PROCESÓW BIZNESOWYCH</p>
                </a>
                <a href="/mobile" className="offer-box">
                    <img src={integrationIcon} alt="integrationIcon"/>
                    <p className="box-title">Integracja systemów informatycznych</p>
                </a>
            </div>
        </div>
         
        {/* <div className="changeContent__cont--item offer__img" 
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)} 
            onClick={() => setHover(true)}>
        <div className="changeContent__cont--item--inside">
            
            {!inHover && 
            <>
            <h3 className="offer__cont--title deley_duration">{languageData.Offer}</h3>
            </>}
            {inHover && 
            <>
            <h3 className="offer__cont--title deley_duration">{languageData.Offer}</h3>

            </>}           
           
            </div>
        </div>
        <div className="changeContent__cont--item hiper__img"
        onMouseEnter={() => setHover1(true)}
        onMouseLeave={() => setHover1(false)}
        onClick={() => setHover1(true)}>
        <div className={`changeContent__cont--item--inside ${inHover1 ? "show__up" : ""}`}>
        
            {!inHover1 && 
            <>
            <h3 className="offer__cont--title">{languageData.HiperAutomat}</h3>
            </>}
            {inHover1 && 
            <>
            
            <p className="offer__cont--description">{languageData.HiperAutomatDesc}</p>
            </>}           
            </div>
       
        </div>
        <div className="changeContent__cont--item rpa__img"
        onMouseEnter={() => setHover2(true)}
        onMouseLeave={() => setHover2(false)}
        onClick={() => setHover2(true)}>
        <div className={`changeContent__cont--item--inside ${inHover2 ? "show__up" : ""}`}>
        
            {!inHover2 && 
            <>
            <h3 className="offer__cont--title">RPA</h3>
            </>}
            {inHover2 && 
            <>
         
            <p className="offer__cont--description">{languageData.RPA}</p>
            </>}    
            </div>      
            </div>
                
    
    
         
        <div className="changeContent__cont--item mlai__img" 
            onMouseEnter={() => setHover3(true)}
            onMouseLeave={() => setHover3(false)}
            onClick={() => setHover3(true)}>
            <div className={`changeContent__cont--item--inside ${inHover3 ? "show__up" : ""}`}>
          
            {!inHover3 && 
            <>
            <h3 className="offer__cont--title">ML/AI</h3>
            </>}
            {inHover3 && 
            <>
          
            <p className="offer__cont--description">{languageData.MLAL}</p>
            </>}           
            
            </div>
        </div>
        <div className="changeContent__cont--item mobileApp__img"
        onMouseEnter={() => setHover4(true)}
        onMouseLeave={() => setHover4(false)}
        onClick={() => setHover4(true)}>
        <div className={`changeContent__cont--item--inside ${inHover4 ? "show__up" : ""}`}>
       
            {!inHover4 && 
            <>
            <h3 className="offer__cont--title">Mobile APP's</h3>
            </>}
            {inHover4 && 
            <>
            <p className="offer__cont--description">{languageData.MobileApp}</p>
            </>}           
           
        </div>
        </div>
        <div className="changeContent__cont--item ibmps__img"
        onMouseEnter={() => setHover5(true)}
        onMouseLeave={() => setHover5(false)}
        onClick={() => setHover5(true)}>
        <div className={`changeContent__cont--item--inside ${inHover5 ? "show__up" : ""}`}>
           
            {!inHover5 && 
            <>
            <h3 className="offer__cont--title">{languageData.Ibmps}</h3>
            </>}
            {inHover5 && 
            <>
          
            <p className="offer__cont--description">{languageData.IbmpsDesc}</p>
            </>}           
            </div>
    
            </div> */}
   
    </div>
    
  )
}


export default FifthSection;
