export function changeLanguage(data){
    return async function(dispatch /*getState */ ){
        let jsonData = {}
        if(data === 'pl'){
           await import('../reducers/languageReducer/pl.json').then(data => jsonData = data)
        } else if(data === 'en' ) {
            await import('../reducers/languageReducer/en.json').then(data => jsonData = data)
        }
    
        await dispatch(setLanguage(jsonData))
    }
}

export function setLanguage(data){
    return {type: 'SET_LANGUAGE', payload: data}
}

