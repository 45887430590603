import React from "react";
import { A } from "hookrouter";
import { useSelector } from "react-redux";

const NonExistentPage = () => {
    const languageData = useSelector(state => state.languageReducer.data);

    return (
      <div className="notFoundPage">
        {" "}
        <h1>404</h1> 
        <p>{languageData.notFound}</p>
        <A className="notFoundPage__btn" href="/">{languageData.notFoundButton}</A>
      </div>
    );
}
export default NonExistentPage;
