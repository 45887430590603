import React from 'react';
import {useSelector} from 'react-redux';


const FindOutSectionOld = (props) => {


    const languageData = useSelector(state => state.languageReducer.data);


    function goOffer() {
        setTimeout(() => {
        document.getElementById('oferta').scrollIntoView({behavior: 'smooth'});
      }, 500);
      }

    return(<>
        <div id="findOut" className="bg_about home__third--img">
        
    
       
            <div className="home__third--cont third_section">          
                             
                <div>
                    <h1>30%</h1>
                    <div>
                    <h3>{languageData.Economies1}</h3>    
                    <h3>{languageData.Economies2}</h3>    
                    
                      <a onClick={goOffer}   target="_blank" >
                          <button className="contact__const--btn">{languageData.FindOutMore}</button>
                        </a>
                    
                    </div>
                </div>
                
                
                
        </div>
        </div>
        
        </>
        
    )
}

export default FindOutSectionOld;
