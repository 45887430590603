import React from 'react';
import {useSelector} from 'react-redux';

const SecondSectionOld = (props) => {

    const languageData = useSelector(state => state.languageReducer.data);

    

    return(
        
        <div id="aboutUs" className="aboutUs__cont">
                <div className="about__cont--change">

                
                <div className="about__cont--txt">

                <p className="about__cont--description" style={{ ...props }}>
                {languageData.AmniseDescription1}
                </p>
       
          

                <p className="about__cont--description" style={{ ...props }}>
                {languageData.AmniseDescription2}
                </p>
              
                </div>
                <div className="functionality__cont--withImg">
              
                </div>
                
                </div>
        </div>
    )
}

export default SecondSectionOld


