// import React from 'react';
import React, { Component } from "react";
import ProgressLine from "../Home/ProgressLine";
import {useSelector} from 'react-redux';
import UiSection from '../Home/UiSection';
import { Helmet } from "react-helmet";

export default class Customers extends Component {

  constructor(props) {
    super(props);
    // this.languageData = useSelector(state => state.languageReducer.data);
    this.state = {
      view: 'autopunkt',
    };
  }

  setView(viewName) {
    const correctViewName = !!(viewName === 'autopunkt' || viewName === 'holding' || viewName === 'express');
    if (viewName === 'autopunkt' || viewName === 'holding' || viewName === 'express') {
      this.setState((state) => ({
        view: correctViewName ? viewName : 'autopunkt',
      }));
    }
  }

  render() {
    return(
      <div>
      <Helmet>
        <title>Referencje - AmnisCode</title>
        <meta name="title" content="Referencje - AmnisCode"/>
        <meta name="description" content="Przekonaj się, dlaczego nasi klienci są zadowoleni z naszych usług! Zapoznaj się z naszymi realizacjami i dołącz do grona zadowolonych klientów!"/>
        <link rel="canonical" href="https://amniscode.pl/customers"/>
        <meta name="robots" content="index, follow"/>
      </Helmet>
        <div className="customers_cont1">
          <div className="page-title-content__wrapper-mid">
              <div className="title-container">
                <h1 className="title">Sprawdź nasze referencje</h1>
                <ProgressLine backgroundColor="#CACFD4" visualParts={[{ percentage: "23%", color: "#69afea" }]}/>
                <h3 className="subtitle">Zobacz korzyści jakie osiągnęli nasi klienci, zatrudniając cyfrowego pracownika</h3>
              </div>
          </div>
        </div>
  
        <div className="content__wrapper customers_cont2">
          {/* <h2 className="blue-bold__subtitle">{this.languageData.rpaYourGoalSectionText1}<br/>{this.languageData.rpaYourGoalSectionText2}</h2> */}
          {/* <div className="sm-blue-line"></div> */}
          <div className="tabs-container">
            <div className="txt-sm choose-branch-container">
              <div>
                <p onClick={() => this.setView('autopunkt')} className={'txt-blue txt-bold cta-btn ' + (this.state.view !== 'autopunkt' ? 'txt-disabled' : '')}>Autopunkt NS</p>
                <div className={'sm-blue-line ' + (this.state.view !== 'autopunkt' ? 'line-disabled' : '')}></div>
              </div>
              <div>
                <p onClick={() => this.setView('holding')} className={'txt-blue txt-bold cta-btn ' + (this.state.view !== 'holding' ? 'txt-disabled' : '')}>Centrum Holding 1</p>
                <div className={'sm-blue-line ' + (this.state.view !== 'holding' ? 'line-disabled' : '')}></div>
              </div>
              <div>
                <p onClick={() => this.setView('express')} className={'txt-blue txt-bold cta-btn ' + (this.state.view !== 'express' ? 'txt-disabled' : '')}>Express</p>
                <div className={'sm-blue-line ' + (this.state.view !== 'express' ? 'line-disabled' : '')}></div>
              </div>
            </div>
            <div className="tab-content-container">
              { this.state.view === 'autopunkt' &&
                <div>
                  <p className="txt-blue txt-bold">Zawieranie nowych i kontynuacje polis krótkoterminowych</p>
                  <div className="txt-sm">
                    <span className="txt-blue txt-bold">Autopunkt </span>
                    <span>to ogólnopolska sieć sprzedaży samochodów używanych z największym wyborem aut w różnych klasach i segmentach. Firma ma obecnie 13 oddziałów w 10 największych miastach w Polsce. W ramach kompleksowej obsługi Autopunkt oferuje swoim klientom oferty ubezpieczenia samochodu, a także kredytu i leasingu. Firma także kupuje od klientów auta, które musi potem na krótkie terminy ubezpieczać. <br/><br/>Celem projektu było zautomatyzowanie procesu zawierania nowych umów ubezpieczenia i kontynuacji polis krótkoterminowych dla samochodów znajdujących się w ofercie Autopunktu. Z uwagi na dużą liczbę samochodów sprzedawanych i skupowanych przez firmę, liczba zawieranych polis sięga kilkuset w skali miesiąca. Przed automatyzacją, proces związany z kupnem polisy był rozproszony i realizowany zarówno przez pracowników działu administracji, jak i handlowców w poszczególnych lokalizacjach na terenie całej Polski. Przeprowadzona wspólnie z AmnisCode analiza jednoznacznie wykazała, że duża część powtarzalnych prac może być z powodzeniem realizowana przez robota, a do koordynacji i nadzoru nad całym procesem wystarczy jeden pracownik. Obecnie robot po wyfiltrowaniu pojazdów, których ważność polisy mija za 2 dni weryfikuje w wewnętrznym systemie DMS wszystkie wymagane do polisy dane, loguje się do zewnętrznego  agregatora ofert ubezpieczeniowych, wprowadza dane i zawiera polisę.  Po wykonanej operacji przesyła potwierdzenie do pracownika Autopunktu odpowiedzialnego za ten obszar. Na koniec każdego dnia robot wysyła raport zawartych polis.<br/><br/></span>
                  </div>
                  <div className="txt-sm">
                    <i>– Za sprawą robotyzacji w tym obszarze osiągnęliśmy bardzo wymierną oszczędność czasu. Dzięki temu nasi pracownicy mogą podjąć nowe zadania, wykraczające poza powtarzalne czynności. Jest to zatem inwestycja w rozwój naszego biznesu – mówi Zygmunt Cesnyka, Dyrektor Operacyjny Autopunkt.</i>
                  </div>
                </div>
              }
              { this.state.view === 'holding' &&
                <div>
                  <p className="txt-blue txt-bold">Zaczytywanie zwolnień z PUE do enova365</p>
                  <div className="txt-sm">
                    <span className="txt-blue txt-bold">Holding 1 </span>
                    <span>to polska grupa kapitałowa z ponad trzydziestoletnią tradycją, od lat notowana w pierwszej dwusetce największych firm Rzeczpospolitej. Zespół 1600 pracowników działa dziś równolegle w kilku branżach: motoryzacji (m.in. multidealer samochodowy Grupa PGD, wypożyczalnia aut Express Car Rental, największy carsharing w Polsce – Traficar), budownictwa mieszkaniowego oraz biurowego (grupa deweloperska Megapolis), a także w obszarze innowacji technologicznych.<br/><br/>Proces, którego automatyzacji wspólnie z GK Holding 1 podjęła się  firma AmnisCode, była wymiana danych pomiędzy używanym przez klienta systemem ERP enova365 a Platformą Usług Elektronicznych ZUS. Przed wdrożeniem pracownik działu kadr po zalogowaniu na PUE ZUS musiał dla każdej ze spółek wchodzić w zakładkę „zaświadczenia lekarskie”, weryfikować czy nie pojawiły się nowe e-ZLA (elektroniczne zwolnienia lekarskie), pobrać dane w pliku csv i następnie wpisać dane do enova365, również logując się dla każdej ze spółek oddzielnie.<br/><br/></span>
                  </div>
                  <div className="txt-sm">
                    <i>– Z racji tego, że w ramach Holdingu 1 funkcjonuje kilkanaście spółek, a liczba pracowników przekracza 1600 osób, było to zadanie czasochłonne. Po wdrożeniu pracownik działu kadr nie musi poświęcać czasu na tę czynność. Podejmuje działanie jedynie wtedy, gdy podczas zaczytywania pojawią się błędy, o czym robot informuje nas mailowo – mówi Małgorzata Kalinowska-Szuper, kierownik działu personalnego Centrum Holding 1.</i>
                  </div>
                </div>
              }
              { this.state.view === 'express' &&
                <div>
                  <p className="txt-blue txt-bold">Rozwój robota do powiadomień windykacyjnych</p>
                  <div className="txt-sm">
                    <span className="txt-blue txt-bold">Express </span>
                    <span>to działająca od ponad dwudziestu lat największa polska wypożyczalnia samochodów. W swojej flocie ma ponad dwanaście tysięcy pojazdów osobowych, dostawczych i ciężarowych. Zespół Express Car Rental tworzy ponad sześćset osób, zaangażowanych w codzienną obsługę klientów na terenie całego kraju.<br /><br /></span>
                  </div>                 
                  <div className="txt-sm">
                    <span>Celem, który przyświecał zatrudnieniu robota w dziale windykacji, było usprawnienie procesu, podniesienie wydajności oraz jakości. Kierownik działu windykacji codziennie eksportował z systemu finansowo-księgowego do excela raport zaległych faktur i rozdzielał dłużników pomiędzy pracowników działu. Każdorazowe przygotowanie maila z wezwaniem do zapłaty na poszczególnych etapach windykacji było bardzo czasochłonne, gdyż pracownik przygotowując maila odpowiedniej treści, musiał ręcznie wpisywać wszystkie dane, jak numer faktury, kwotę, datę dokumentu i termin płatności oraz wyszukiwać w systemie adres. Po każdej sprawie windykator musiał zapisać notatkę w systemie finansowo-księgowym i ustawić datę następnego kontaktu z klientem.<br/><br/></span>
                  </div>
                  <div className="txt-sm">
                    <i>– Obecnie wszystkie czynności zlecam robotowi stworzonemu przez AmnisCode. I to zlecam jednym plikiem z zestawieniem przeterminowanych należności. A potem tylko odbieram raport z nieomal pozbawionej błędów pracy. Ważnym jest też fakt, że skuteczność zrobotyzowanej windykacji okazała się znacząco wyższa niż w przypadku pracy człowieka – zdradza Tomasz Śmietana, kierownik działu windykacji w Expressie.</i>
                  </div>
                </div>
              }
            </div>
          </div>
          {/* <div className="d-flex-sp-center">
            <a className="btn-orange mt-45" href="https://amniscode.pl/#kontakt">{this.languageData.rpaYourGoalSectionText5}</a>
          </div> */}
        </div>
  
        <div>
          <UiSection />
        </div>
      </div>
    )
  }
}