import styled from "styled-components";

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  background: rgb(62,54,153) !important;
  background: linear-gradient(135deg, rgba(62,54,153,1) 0%, rgba(83,112,192,1) 35%, rgba(105,175,234,1) 100%) !important; 
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
  height: 100vh;
  text-align: right;
  padding: 4em 2em;
  position: absolute;
  top: 0;
  right: -1px;
  transition: transform 0.3s ease-in-out;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }

  p {
    font-size: 1.5em;
    text-transform: uppercase;
    margin:0.5em;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: white;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: ${({ theme }) => theme.primaryHover};
    }
  }
`;
