import React from 'react';
import ProgressLine from "../Home/ProgressLine";
import aimIcon from '../../../assets/icons/aim1.png';
import cloudIcon from '../../../assets/icons/cloud-service.svg';
import teamIcon from '../../../assets/icons/team1.png';
import aiBrainIcon from '../../../assets/icons/ai-brain1.png';
import analyticsIcon from '../../../assets/icons/analytics1.svg';
import aiBrainIcon2 from '../../../assets/icons/ai-brain2.png';
import networkIcon from '../../../assets/icons/network1.png';
import codeZoomIcon from '../../../assets/icons/code-zoom.png';
import stepIcon1 from '../../../assets/icons/step1.png';
import stepIcon2 from '../../../assets/icons/step2.png';
import stepIcon3 from '../../../assets/icons/step3.png';
import stepIcon4 from '../../../assets/icons/step4.png';
import stepIcon5 from '../../../assets/icons/step5.png';
import stepIcon6 from '../../../assets/icons/step6.png';
import stepIcon7 from '../../../assets/icons/step7.png';
import stepIcon8 from '../../../assets/icons/step8.png';
import UIbg from '../../../assets/Uibg1.jpg';
import implIcon1 from '../../../assets/icons/impl1.png';
import implIcon2 from '../../../assets/icons/impl2.png';
import implIcon3 from '../../../assets/icons/impl3.png';
import implIcon4 from '../../../assets/icons/impl4.png';
import implIcon5 from '../../../assets/icons/impl5.png';
import {useSelector} from 'react-redux';
import { Helmet } from "react-helmet";


const Rpa = () => {
  const languageData = useSelector(state => state.languageReducer.data);

  return(
    <div>
    <Helmet>
        <title>RPA - Robotyzacja procesów - AmnisCode</title>
        <meta name="title" content="RPA - Robotyzacja procesów - AmnisCode"/>
        <meta name="description" content="Zwiększ efektywność swojej firmy dzięki RPA! Dzięki automatyzacji procesów oszczędzisz czas oraz pieniądze. Zapraszamy!"/>
        <link rel="canonical" href="https://amniscode.pl/rpa"/>
        <meta name="robots" content="index, follow"/>
    </Helmet>
      <div className="rpa_cont1">
        <div className="page-title-content__wrapper">
            <div className="title-container">
              <h1 className="title">{languageData.ourServicesRpa}</h1>
              <ProgressLine backgroundColor="#CACFD4" visualParts={[{ percentage: "23%", color: "#69afea" }]}/>
              <h3 className="subtitle">{languageData.rpaSubtitle}</h3>
            </div>
        </div>
      </div>

      <div className="content__wrapper rpa_cont2">
        <h2 className="blue-bold__subtitle">{languageData.rpaYourGoalSectionText1}<br/>{languageData.rpaYourGoalSectionText2}</h2>
        <div className="sm-blue-line"></div>
        <div className="d-flex-sp-btwn">
          <div className="txt-sm txt-container">{languageData.rpaYourGoalSectionText3}</div>
          <div className="txt-sm txt-container">{languageData.rpaYourGoalSectionText4}</div>
        </div>
        <div className="d-flex-sp-center">
          <a className="btn-orange mt-45" href="https://amniscode.pl/#kontakt">{languageData.rpaYourGoalSectionText5}</a>
          </div>
      </div>

      <div className="content__wrapper rpa_cont3">
        <h2 className="blue-bold__subtitle el-center">{languageData.implementRpa1}</h2>
        <div className="sm-blue-line el-center"></div>
        <div className="d-flex-sp-btwn mt-50">
          <div className="info-box info-box-sp-btwn shdw-sm">
            <img src={cloudIcon} alt="cloud icon"/>
            <p className="txt-blue">{languageData.implementRpa2}</p>
          </div>
          <div className="info-box info-box-sp-btwn shdw-sm">
            <img src={aimIcon} alt="aim icon"/>
            <p className="txt-blue">{languageData.implementRpa3}</p>
          </div>
          <div className="info-box info-box-sp-btwn shdw-sm">
            <img src={teamIcon} alt="team icon"/>
            <p className="txt-blue">{languageData.implementRpa4}</p>
          </div>
        </div>
      </div>

      <div className="content__wrapper rpa_cont4">
        <h2 className="blue-bold__subtitle el-center">{languageData.rpaOffer1}</h2>
        <div className="sm-blue-line el-center"></div>
        <p className="txt-sm txt-blue w-75 el-center">{languageData.rpaOffer2}</p>

        <div className="d-flex-sp-center mt-50">
          <div className="info-box shdw-sm">
            <p className="txt-blue txt-bold">{languageData.rpaOffer3}</p>
            <img src={analyticsIcon} alt="analytics icon"/>
          </div>
          <div className="info-box shdw-sm">
            <p className="txt-blue txt-bold">{languageData.rpaOffer4}</p>
            <img src={aiBrainIcon} alt="ai icon"/>
          </div>
          <div className="info-box shdw-sm">
            <p className="txt-blue txt-bold">{languageData.rpaOffer5}</p>
            <img src={networkIcon} alt="network icon"/>
          </div>
          <div className="info-box shdw-sm">
            <p className="txt-blue txt-bold">ML/AI</p>
            <img src={aiBrainIcon2} alt="ai icon"/>
          </div>
          <div className="info-box shdw-sm">
            <p className="txt-blue txt-bold">{languageData.rpaOffer6}</p>
            <img src={codeZoomIcon} alt="ai icon"/>
          </div>
        </div>
      </div>

      <div className="rpa_cont5">
        <h2 className="blue-bold__subtitle el-center">{languageData.partnershipStepsRpa1}</h2>
        <div className="sm-blue-line el-center"></div>
        <div className="d-flex mt-50">
          <div className="rpa-step-box">
            <img src={stepIcon1} alt="handshake"/>
            <p className="step-title txt-bold">{languageData.partnershipStepsRpa2} 1</p>
            <p className="el-center step-info-text">{languageData.partnershipStepsRpa3}</p>
          </div>
          <div className="rpa-step-box">
            <img src={stepIcon2} alt="system"/>
            <p className="step-title txt-bold">{languageData.partnershipStepsRpa2} 2</p>
            <p className="el-center step-info-text">{languageData.partnershipStepsRpa4}</p>
          </div>
          <div className="rpa-step-box">
            <img src={stepIcon3} alt="office talk"/>
            <p className="step-title txt-bold">{languageData.partnershipStepsRpa2} 3</p>
            <p className="el-center step-info-text">{languageData.partnershipStepsRpa5}</p>
          </div>
          <div className="rpa-step-box">
            <img src={stepIcon4} alt="mail password"/>
            <p className="step-title txt-bold">{languageData.partnershipStepsRpa2} 4</p>
            <p className="el-center step-info-text">{languageData.partnershipStepsRpa6}</p>
          </div>
          <div className="rpa-step-box">
            <img src={stepIcon5} alt="robot"/>
            <p className="step-title txt-bold">{languageData.partnershipStepsRpa2} 5</p>
            <p className="el-center step-info-text">{languageData.partnershipStepsRpa7}</p>
          </div>
          <div className="rpa-step-box">
            <img src={stepIcon6} alt="talk"/>
            <p className="step-title txt-bold">{languageData.partnershipStepsRpa2} 6</p>
            <p className="el-center step-info-text">{languageData.partnershipStepsRpa8}</p>
          </div>
          <div className="rpa-step-box">
            <img src={stepIcon7} alt="office"/>
            <p className="step-title txt-bold">{languageData.partnershipStepsRpa2} 7</p>
            <p className="el-center step-info-text">{languageData.partnershipStepsRpa9}</p>
          </div>
          <div className="rpa-step-box">
            <img src={stepIcon8} alt="settings"/>
            <p className="step-title txt-bold">{languageData.partnershipStepsRpa2} 8</p>
            <p className="el-center step-info-text">{languageData.partnershipStepsRpa10}</p>
          </div>
        </div>
      </div>

      <div className="rpa_cont6">
        <div className="rpa-ask-for-contact__cont" style={{backgroundImage: `url(${UIbg})` }}>
          <h2 className="rpa-ask-for-contact__title">{languageData.rpaJourney}</h2>
          <a className="btn-orange mt-45" href="https://amniscode.pl/#kontakt">{languageData.askForContact}</a>
        </div>
      </div>

      <div className="content__wrapper rpa_cont7">
        <h2 className="blue-bold__subtitle el-center">{languageData.implementRpaSteps1}</h2>
        <div className="sm-blue-line el-center"></div>
        <p className="txt-sm txt-blue w-75 el-center">{languageData.implementRpaSteps2}</p>
        <p className="sub-title txt-blue el-center txt-bold">{languageData.implementRpaSteps3}:</p>
        <div className="impl-container d-flex-sp-btwn">
          <div className="impl-step-box shdw-sm">
            <div className="img-cont">
              <img src={implIcon1} alt="talk"/>
            </div>
            <p className="impl-step-title txt-bold">{languageData.implementRpaSteps4}</p>
            <p className="el-center impl-step-info-text">{languageData.implementRpaSteps5}</p>
            <p className="el-center impl-step-info-text">{languageData.implementRpaSteps6}</p>
            <p className="el-center impl-step-info-text">{languageData.implementRpaSteps7}</p>
            <p className="el-center impl-step-info-text">{languageData.implementRpaSteps8}</p>
            <p className="el-center impl-step-info-text">{languageData.implementRpaSteps9}</p>
          </div>
          <div className="impl-step-box shdw-sm">
            <div className="img-cont">
              <img src={implIcon2} alt="system"/>
            </div>
            <p className="impl-step-title txt-bold">{languageData.implementRpaSteps10}</p>
            <p className="el-center impl-step-info-text">{languageData.implementRpaSteps11}</p>
            <p className="el-center impl-step-info-text">{languageData.implementRpaSteps12}</p>
            <p className="el-center impl-step-info-text">{languageData.implementRpaSteps13}</p>
            <p className="el-center impl-step-info-text">{languageData.implementRpaSteps14}</p>
          </div>
          <div className="impl-step-box shdw-sm">
            <div className="img-cont">
              <img src={implIcon3} alt="coding"/>
            </div>
            <p className="impl-step-title txt-bold">{languageData.implementRpaSteps15}</p>
            <p className="el-center impl-step-info-text">{languageData.implementRpaSteps16}</p>
            <p className="el-center impl-step-info-text">{languageData.implementRpaSteps17}</p>
            <p className="el-center impl-step-info-text">{languageData.implementRpaSteps18}</p>
            <p className="el-center impl-step-info-text">{languageData.implementRpaSteps19}</p>
          </div>
          <div className="impl-step-box shdw-sm">
            <div className="img-cont">
              <img src={implIcon4} alt="settings"/>
            </div>
            <p className="impl-step-title txt-bold">{languageData.implementRpaSteps20}</p>
            <p className="el-center impl-step-info-text">{languageData.implementRpaSteps21}</p>
            <p className="el-center impl-step-info-text">{languageData.implementRpaSteps22}</p>
            <p className="el-center impl-step-info-text">{languageData.implementRpaSteps23}</p>
            <p className="el-center impl-step-info-text">{languageData.implementRpaSteps24}</p>
          </div>
          <div className="impl-step-box shdw-sm">
            <div className="img-cont">
              <img src={implIcon5} alt="send"/>
            </div>
            <p className="impl-step-title txt-bold">{languageData.implementRpaSteps25}</p>
            <p className="el-center impl-step-info-text">{languageData.implementRpaSteps26}</p>
            <p className="el-center impl-step-info-text">{languageData.implementRpaSteps27}</p>
            <p className="el-center impl-step-info-text">{languageData.implementRpaSteps28}</p>
            <p className="el-center impl-step-info-text">{languageData.implementRpaSteps29}</p>
          </div>
        </div>
        <div style={{paddingTop: 65 + 'px'}}></div>
        <h2 class="blue-bold__subtitle el-center">{languageData.RpaSEOText1}</h2>
        <div class="sm-blue-line el-center"></div>
        <p class="txt-sm txt-blue el-center">
          {languageData.RpaSEOText2}
        </p>
        <div style={{paddingTop: 35 + 'px'}}></div>
        <h2 class="blue-bold__subtitle el-center">{languageData.RpaSEOText3}</h2>
        <div class="sm-blue-line el-center"></div>
        <p class="txt-sm txt-blue el-center">{languageData.RpaSEOText4}</p>
        <br/>
        <p class="txt-sm txt-blue el-center">{languageData.RpaSEOText5}</p>
        <ul class="txt-sm txt-blue" style={{textAlign: 'center', listStylePosition: 'inside'}}>

        <li>{languageData.RpaSEOText6}</li>
        <li>{languageData.RpaSEOText7}</li>
        <li>{languageData.RpaSEOText8}</li>
        <li>{languageData.RpaSEOText9}</li>
        <li>{languageData.RpaSEOText10}</li>
        <li>{languageData.RpaSEOText11}</li>
        <li>{languageData.RpaSEOText12}</li>
        </ul>
        <div style={{paddingTop: 35 + 'px'}}></div>
        <h2 class="blue-bold__subtitle el-center">{languageData.RpaSEOText13}</h2>
        <div class="sm-blue-line el-center"></div>
        <p class="txt-sm txt-blue el-center">
        {languageData.RpaSEOText14}
        </p>

        <p class="txt-sm txt-blue el-center">
        {languageData.RpaSEOText15}
        </p>
    </div>
  </div>
  )
}

export default Rpa;