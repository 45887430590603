import React from "react";
import logo from "../../../assets/logo.png";
import { useSelector } from "react-redux";
import ProgressLine from "./ProgressLine";
import Countdown from "./Countdown";
import { Spring } from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";

const CounterSection = (props) => {
  const languageData = useSelector((state) => state.languageReducer.data);
  const currentDate = new Date();
  const year =
    currentDate.getMonth() === 11 && currentDate.getDate() > 23
      ? currentDate.getFullYear() + 1
      : currentDate.getFullYear();

  return (
    <>
      <div id="home" className="bg_AmniseFirst">
        <div className="amniseFirst__cont section">
          {/* <VisibilitySensor>
          {({ isVisible }) => (
            <Spring delay={500} to={{ opacity: isVisible ? 1 : 0 }}>
              {({ opacity }) => (
                <img src={logo} className="logo" alt="logo" style={{opacity }} />
              )}
            </Spring>
          )}
        </VisibilitySensor> */}

          <div className="amniseFirst__cont--info">
            <h1 className="amniseFirst__cont--title">
              {languageData.HeadTitle}
            </h1>
            <h3 className="amiseFirst__cont--subtitle">
              {languageData.HeadTitle2}
            </h3>
          </div>
          <VisibilitySensor>
            {({ isVisible }) => (
              <Spring
                config={{ duration: 500 }}
                delay={500}
                to={{ opacity: isVisible ? 1 : 0 }}
              >
                {({ opacity }) => (
                  <>
                    <ProgressLine
                      style={{ opacity }}
                      backgroundColor="#CACFD4"
                      visualParts={[
                        {
                          percentage: "23%",
                          color: "#69afea",
                        },
                      ]}
                    />

                    <div
                      className="amniseFirst__cont--info2"
                      style={{ opacity }}
                    >
                      {/* <h3 className="amiseFirst__cont--subtitle--ul">{languageData.HeadSubtitle1}</h3> */}
                      {/* <h3 className="amiseFirst__cont--subtitle--ul">{languageData.HeadSubtitle2}</h3> */}
                      <h4 className="amiseFirst__cont--subtitle--ul">
                        #{languageData.HomeHeaderSubtitle1}
                      </h4>
                      <h4 className="amiseFirst__cont--subtitle--ul">
                        #{languageData.HomeHeaderSubtitle2}
                      </h4>
                      <h4 className="amiseFirst__cont--subtitle--ul">
                        #{languageData.HomeHeaderSubtitle3}
                      </h4>
                    </div>
                  </>
                )}
              </Spring>
            )}
          </VisibilitySensor>
          {/* { <div className="webinar__info--wrapper">
            <div className="webinar__info--cont">
              <div className="webinar__info">
                <h3>{languageData.WebinarInfo}</h3>
              </div>
              <Countdown date={`2022-05-26T11:00:00`} />
              <a
                href="https://amniscode.clickmeeting.com/cyfryzacja-procesow-sposobem-na-redukcje-kosztow-biurowy-robot-w-twojej-firmie-/register?_ga=2.235963139.380889472.1652087567-2075577178.1649670506"
                target="_blank"
              >
                <button className="contact__const--btn">
                  {languageData.FindOutMore}
                </button>
              </a>
            </div>
          </div> } */}
        </div>
      </div>
    </>
  );
};

export default CounterSection;
