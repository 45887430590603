import React, { useEffect, useState, useRef } from "react";
import { A } from "hookrouter";
import styled, { createGlobalStyle } from "styled-components";
import {useDispatch} from 'react-redux';
import {changeLanguage} from '../../redux/actions/languageAction';
import {useSelector} from 'react-redux';
import Burger from '../Burger';
import Menu from '../MenuMobile';
import logoFullName from '../../assets/logo_fullname.png';
import { useOnClickOutside } from "../../hooks/hooks";
// import logoFullName from '../../assets/logo_fullname.png';
function Header() {

  const dispatch = useDispatch();
  const languageData = useSelector(state => state.languageReducer.data);
  const [windowDimension, setWindowDimension] = useState(null);
  const [navbar,setNavbar] = useState(false);
  const [automationOpen, setAutomationOpen]  = useState(false);

  
  useEffect(() => {
    setWindowDimension(window.innerWidth);
    
    
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    
    
    return () => {
      window.removeEventListener("resize", handleResize);

      
    }
    
  }, []);

  const isMobile = windowDimension <= 1110;
  const [status, setStatus] = useState(false);

  
  function goHome() {
  //   setTimeout(() => {
  //   document.getElementById('home').scrollIntoView({behavior: 'smooth'});
  // }, 500);
  }
  function goAbout() {
    
    // setTimeout(() => {
    //   document.getElementById('about').scrollIntoView({behavior: 'smooth'});
    // }, 500);
  }

  function goToElement(elementId) {
    setTimeout(() => {
      let element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({block: 'center', behavior: 'smooth'});
      }
    }, 300);
  }

  function goOffer() {
    setTimeout(() => {
      document.getElementById('oferta').scrollIntoView({behavior: 'smooth'});
    }, 500);
    setOpen(!open);
  }

  const [open, setOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));


  // const changeBg = () => {
  //   if(window.pageYOffset >= 100) {
  //     setNavbar(true);
  //   } else {
  //     setNavbar(false);
  //   }
  // }

  function scrollTop() {
    window.scrollTo(0, 0);
  }

  // window.addEventListener('scroll', changeBg);


  return (
    <Styles.Wrapper>
      <CSSReset />

      {isMobile ? (
          <>
          <div ref={node}>
          <Burger open={open} setOpen={setOpen} />
          <Menu open={open} setOpen={setOpen} />
        </div>
        </>
      ) : (
        <Navbar.Wrapper id="header" className={navbar ? 'navbar navActive' : 'navbar'}>
          <div><A href="/"><Navbar.Logo alt="AmnisCode - automatyzacja procesów" src={logoFullName}/></A></div>
          <Navbar.Items>
            {/* <Navbar.Item onClick={goHome}><A href="/">Home</A></Navbar.Item> */}
            <Navbar.Item onClick={() => scrollTop()}><A href="/about">{languageData.menuAbout}</A></Navbar.Item>
            {/* <li><a href="https://amniscode.pl/about">{languageData.menuAbout}</a></li> */}
            <Navbar.Item onClick={() => scrollTop()}><A href="/rpa">RPA</A></Navbar.Item>
            <li className="dropdown enWbZx"> 
              {/* <p onMouseEnter={() => {setAutomationOpen(!automationOpen)}}>Automatyzacja procesów</p> */}
            <a onClick={() => {setAutomationOpen(!automationOpen)}}>Automatyzacja procesów</a>
              <ul onMouseLeave={() => {setAutomationOpen(automationOpen => {automationOpen = false})}} className="dropdown-content">
                <DropdownItem classNameLink={`automation-link ${automationOpen ? 'active' : 'inactive'}`} className={`dropdown-item ${automationOpen ? 'active' : 'inactive'}`} text = {"biznesowych"} link = {"business-automation"} method = {() => scrollTop()}/>
                <DropdownItem classNameLink={`automation-link ${automationOpen ? 'active' : 'inactive'}`} className={`dropdown-item ${automationOpen ? 'active' : 'inactive'}`} text = {"logistycznych"} link = {"logistics-automation"} method = {() => scrollTop()}/>
                <DropdownItem classNameLink={`automation-link ${automationOpen ? 'active' : 'inactive'}`} className={`dropdown-item ${automationOpen ? 'active' : 'inactive'}`} text = {"produkcyjnych"} link = {"production-automation"} method = {() => scrollTop()}/>
              </ul>
            </li>
            <Navbar.Item onClick={() => scrollTop()}><A href="/customers">{languageData.customers}</A></Navbar.Item>
            <Navbar.Item onClick={() => scrollTop()}><A href="/mobile">Mobile</A></Navbar.Item>
            {/* <Navbar.Item><a href="https://amniscode.pl/#oferta">{languageData.menuOffer}</a></Navbar.Item> */}
            <Navbar.Item onClick={() => goToElement('oferta')}><A href="/">{languageData.menuOffer}</A></Navbar.Item>
            <Navbar.Item onClick={() => goToElement('partners')}><A href="/">{languageData.menuPartner}</A></Navbar.Item>
            <Navbar.Item onClick={() => goToElement('kontakt')}><A href="/">{languageData.menuContact}</A></Navbar.Item>
            <Navbar.Item ><a href="https://amniscode.pl/blog/">Blog</a></Navbar.Item>
            <Navbar.Item>
                
                    <button 
                        className="btn__change" 
                        onClick={() => {
                          setStatus(!status);
                          if(!status){
                            dispatch(changeLanguage('en'));
                          }else if(status){
                            dispatch(changeLanguage('pl'))
                          }                        
                          }}>{`${status ? 'PL' : 'EN'}`}
                          </button>
               
                
            </Navbar.Item>
          </Navbar.Items>
        </Navbar.Wrapper>
      )}
    </Styles.Wrapper>
  );
}

function DropdownItem(props) {
  return(
    <li className={ props.className }>
      <A className={ props.classNameLink } onClick={ props.method } href={`/${props.link}`}>{ props.text }</A>
    </li>
  )
}

const Styles = {
  Wrapper: styled.main`
    display: flex;
    position: fixed;
    top:0;
    z-index: 100000;
    width:100%;
    height: 65px;
  `
};

const Navbar = {
  Wrapper: styled.nav`
    flex: 1;
    align-self: center;
    display: flex;
    align-items: center;
    transition:1s;
    font-family:MaximaMedium;
    background: white;
    justify-content: space-between;
    padding: 5px 10vw;
  `,
  Logo: styled.img`
    width: 11vw;
    min-width: 150px;
  `,
  Items: styled.ul`
    display: flex;
    margin:0;
    list-style: none;
    width: 100%;
    max-width:700px;
    position: relative;
  `,
  Item: styled.li`
    cursor: pointer;
    margin: auto;
    height: 100%;
    -webkit-transition: 1s;
    transition:1s;
    border-bottom:2px solid transparent;
    text-decoration: none;
    color: #083C99;
  `,

 
};


const CSSReset = createGlobalStyle`

`;

export default Header;
