import React, {useState} from "react";
import {useDispatch} from 'react-redux';
import {changeLanguage} from '../../redux/actions/languageAction';
import {useSelector} from 'react-redux';
import { bool, func } from "prop-types";
import { StyledMenu } from "./Menu.styled";
import { A } from "hookrouter";
const Menu = ({ open, setOpen }) => {

  const dispatch = useDispatch();
  const languageData = useSelector(state => state.languageReducer.data);
  const [automationOpen, setAutomationOpen]  = useState(false);
  const [status, setStatus] = useState(false);
  function scrollTop() {
    window.scrollTo(0, 0);
  }

  function goHome() {
    setTimeout(() => {
      document.getElementById('home').scrollIntoView({behavior: 'smooth'});
    }, 500);
    setOpen(!open);
  }
  function goAbout() {
    setTimeout(() => {
      document.getElementById('about').scrollIntoView({behavior: 'smooth'});
    }, 500);
    setOpen(!open);
  }
  function goOffer() {
    setTimeout(() => {
      document.getElementById('oferta').scrollIntoView({behavior: 'smooth'});
    }, 500);
    setOpen(!open);
  }
  function goPartner() {
    setTimeout(() => {
      document.getElementById('partners').scrollIntoView({behavior: 'smooth'});
    }, 500);
    setOpen(!open);
  }
  function goContact() {
    setTimeout(() => {
      document.getElementById('kontakt').scrollIntoView({behavior: 'smooth'});
    }, 500);
    setOpen(!open);
  }
  return (
    <StyledMenu open={open}>
      <A href="/"><p onClick={goHome}>Home</p></A>
      <A href="/about"><p onClick={goAbout}>{languageData.menuAbout}</p></A>
      <A href="/rpa"><p onClick={() => setOpen(!open)}>RPA</p></A>
      <a className="dropdown-hamburger"> 
        <p onClick={() => {setAutomationOpen(!automationOpen)}}>Automatyzacja<br/> procesów</p>
        <ul className={`dropdown-content-hamburger ${automationOpen ? 'active' : 'inactive'}`}>
          <DropdownItem text = {"biznesowych"} link = {"business-automation"} method = {() => {scrollTop(); setOpen(!open)}}/>
          <DropdownItem text = {"logistycznych"} link = {"logistics-automation"} method = {() => {scrollTop(); setOpen(!open)}}/>
          <DropdownItem text = {"produkcyjnych"} link = {"production-automation"} method = {() => {scrollTop(); setOpen(!open)}}/>
        </ul>
      </a>
      <A href="/customers"><p onClick={() => setOpen(!open)}>{languageData.customers}</p></A>
      <A href="/mobile"><p>Mobile</p></A>
      <A href="/"><p onClick={goOffer}>{languageData.menuOffer}</p></A>
      <A href="/"><p onClick={goPartner}>{languageData.menuPartner}</p></A>
      <a className="contact-link" onClick={goContact}>{languageData.menuContact}</a>
      <a href="https://amniscode.pl/blog/"><p >Blog</p></a>
      <button 
         className="btn__change btn__mobile--lang" 
         onClick={() => {
           setStatus(!status);
           if(!status){
             dispatch(changeLanguage('en'));
           }else if(status){
             dispatch(changeLanguage('pl'))
           }                        
           }}>{`${status ? 'PL' : 'EN'}`}
      </button>
    </StyledMenu>
  );
};

function DropdownItem(props) {
  return(
    <li>
      <a onClick={ props.method } href={`/${props.link}`}>{ props.text }</a>
    </li>
  )
}

Menu.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired
};

export default Menu;
