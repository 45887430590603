import React from 'react';
import {useSelector} from 'react-redux';
import ProgressLine from "../Home/ProgressLine";

import { Spring } from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";



const FirstSectionOld = (props) => {

    

    const languageData = useSelector(state => state.languageReducer.data);

    return(<>
        <div id="about" className="bg_about about_img">
        
    
       
            <div className="about__first about_section">
                           <div>
                
                        <h1 className="amniseFirst__cont--title">{languageData.About}</h1>          
           
                <VisibilitySensor>
          {({ isVisible }) => (
            <Spring 
              config={{duration: 500}}
              delay={1000} 
              to={{ opacity: isVisible ? 1 : 0 }}>
              {({ opacity }) => (
                  <>
                
                <ProgressLine
                style={{opacity }}
                backgroundColor="#CACFD4"
                visualParts={[
                {
                    percentage: "23%",
                    color: "#69afea",          
                }
                ]}
            />
            {/* <div className="AmniseFirst__cont--info2" style={{opacity }}>
              <h3 className="AmiseFirst__cont--subtitle--ul">{languageData.HeadSubtitle1}</h3>
              <h3 className="AmiseFirst__cont--subtitle--ul">{languageData.HeadSubtitle2}</h3>
            </div> */}
       
            </>
              )}
            </Spring>
          )}
        </VisibilitySensor>
        </div>
                </div>
      
        </div>
        
        </>
        
    )
}

export default FirstSectionOld
