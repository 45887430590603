import React from 'react';
import ProgressLine from "../Home/ProgressLine";
import doubleArrowRight from '../../../assets/icons/double-arrow-right.svg';
import doubleArrowDown from '../../../assets/icons/double-arrow-down.svg';
import analyze from '../../../assets/icons/analyze.png';
import productionRobot from '../../../assets/icons/production-robot.png';
import documentations from '../../../assets/icons/documentations.png';
import processQuality from '../../../assets/icons/process-quality.svg';
import processGrowth from '../../../assets/icons/process-growth.svg';
import processOffer from '../../../assets/icons/process-offer.svg';
import processQualityService from '../../../assets/icons/process-service-quality.svg';
import processMarket from '../../../assets/icons/process-market.svg';
import UIbg from '../../../assets/Uibg1.jpg';
import {useSelector} from 'react-redux';
import { Helmet } from "react-helmet";
import TrackVisibility from 'react-on-screen';

const AutomationProduction = () => {
  const languageData = useSelector(state => state.languageReducer.data);

  const AutomationTrack1 = ({ isVisible }) => {
    const style = {
        opacity : isVisible ? '1' : '0',
        transform: isVisible ? "translateX(0)" : "translateX(-150%)",
        transition: ".75s ease",
    };
    return <div className="txt-sm txt-blue txt-container" style={style}>Możliwość wdrożenia w przedsiębiorstwie <b>automatyzacji procesów produkcyjnych</b> ma wiele zalet. 
    Dlatego też coraz więcej firm przekonuje się do wprowadzenia tej zmiany u siebie. Robot, który zastępuje człowieka jest przede wszystkim dużo wydajniejszy,
     zmniejsza też ryzyko popełnienia błędu, jak może się to zdarzyć w przypadku pracy człowieka. Dodatkowo wykonuje swoją pracę znacznie dokładniej.
      Zautomatyzowanie ważnych procesów w firmie przynosi korzyść nie tylko przedsiębiorstwu, ale też klientom firmy, gdyż otrzymują oni produkt dopracowany w każdym szczególe.</div>;
  }
  
  const AutomationTrack2 = ({ isVisible }) => {
    const style = {
        opacity : isVisible ? '1' : '0',
        transform: isVisible ? "translateX(0)" : "translateX(150%)",
        transition: ".75s ease",
    };
    return <div className="txt-sm txt-blue txt-container" style={style}>Wspieramy przedsiębiorców, którzy są zdecydowani na wdrożenie automatyzacji w swojej firmie.
    Wyjaśniamy wszelkie obawy i wątpliwości. Warto bowiem zaznaczyć, że na obecnym poziomie rozwoju technologicznego możliwe jest precyzyjne dopasowanie 
    automatyzacji do danej specyfiki czy gałęzi produkcji. Wiemy, jak to zrobić. Na Państwa życzenie przeanalizujemy wszelkie aspekty, 
    dobierzemy odpowiednie rozwiązanie, a także wdrożymy je do Państwa działalności.</div>;
  }
  
  const AutomationTrack3 = ({ isVisible }) => {
    const style = {
        opacity : isVisible ? '1' : '0',
        transform: isVisible ? "translateX(0)" : "translateX(-150%)",
        transition: ".75s ease",
    };
    return <div className="txt-sm txt-blue txt-container" style={style}>Wszelkie plany przedsiębiorstw dotyczące zautomatyzowania procesów produkcyjnych sprowadzają się do jednego istotnego celu. 
    Działanie to ma być odpowiedzią na wymagania klientów, którzy chcą, by zakupiony produkt miał wysoką jakość oraz spełniał ich oczekiwania. Dlatego też skutecznym 
    rozwiązaniem może być wdrożenie RPA (Robotic Process Automation). W obecnych czasach roboty softwarowe mają ogromne znaczenie, jeśli chodzi o <b>automatyzację procesów produkcyjnych</b>.</div>;
  }
  
  const AutomationTrack4 = ({ isVisible }) => {
    const style = {
        opacity : isVisible ? '1' : '0',
        transform: isVisible ? "translateX(0)" : "translateX(150%)",
        transition: ".75s ease",
    };
    return <div className="txt-sm txt-blue txt-container" style={style}>Jedną z branż, która bardzo dobrze może unaocznić to zjawisko, jest branża transportowa i logistyczna, 
    dla których RPA w obecnym czasie staje się koniecznością. Wszystko po to, by sprostać oczekiwaniom klientów i zachęcić do skorzystania z usług. 
    Tego rodzaju roboty wdrożone w przedsiębiorstwie przynoszą wiele korzyści. Przede wszystkim mają wpływ na wzrost przychodów, poszerzenie oferty firmowej, 
    podniesienie jakości obsługi, zwiększają konkurencyjność na rynku, a ponadto mogą znacznie skrócić procedury obowiązujące w danej firmie.</div>;
  }
  
  const AutomationTrack5 = ({ isVisible }) => {
    const style = {
        opacity: isVisible ? '1' : '0',
        transition: ".75s ease",
        textAlign: "center"

    };
    return <p className="txt-sm txt-blue w-75 el-center" style={style}>Opracowujemy rozwiązania, w tym również w zakresie aplikacji mobilnych, 
    przy wykorzystaniu elementów robotyzacji i sztucznej inteligencji. Zapraszamy do skorzystania z prezentowanej oferty.
    Automatyzacja procesów produkcyjnych przynosi wiele korzyści dla przedsiębiorstwa. Wspieramy firmy we wdrażaniu robotów. Zapraszamy do współpracy!</p>;
  }

  const AutomationSteps = ({ isVisible }) => {
    const style = {
        fontSize: isVisible ? "20px" : "0",
        opacity: isVisible ? '1' : '0',
        transition: ".75s ease .25s",
    };
    const styleBox = {
      height: isVisible ? '200px' : '0',
      transition: "height .75s ease, transform .2s ease-out",
  };
    const styleIcons = {
      opacity: isVisible ? '1' : '0',
      transition: ".75s ease .5s",
    };
    return<div className="production-step-container mt-50">
          <div className="production-step" style={styleBox}>
            <p className="txt-blue txt-bold logistics-technology-info" style={style}>Wnikliwa analiza</p>
            <img src={analyze} alt="counter1 icon" className="process-icon" style={styleIcons}/>
          </div>
            <img src={doubleArrowRight} alt="counter1 icon" className="arrow-right" style={styleIcons}/>
            <img src={doubleArrowDown} alt="counter1 icon" className="arrow-down" style={styleIcons}/>
          <div className="production-step" style={styleBox}>
            <p className="txt-blue txt-bold logistics-technology-info" style={style}>Niezbędna dokumentacja</p>
            <img src={documentations} alt="counter1 icon" className="process-icon" style={styleIcons}/>
          </div>
            <img src={doubleArrowRight} alt="counter2 icon" className="arrow-right" style={styleIcons}/>
            <img src={doubleArrowDown} alt="counter2 icon" className="arrow-down" style={styleIcons}/>
          <div className="production-step" style={styleBox}>
            <p className="txt-blue txt-bold logistics-technology-info" style={style}>Wdrożenie robotów</p>
            <img src={productionRobot} alt="counter1 icon" className="process-icon" style={styleIcons}/>
          </div>
        </div>;
  }

  return(
    <div>
    <Helmet>
        <title>Automatyzacja procesów produkcyjnych - AmnisCode</title>
        <meta name="title" content="Automyzacja procesów produkcyjnych - AmnisCode"/>
        <meta name="description" content="Automatyzacja procesów produkcyjnych przynosi wiele korzyści dla przedsiębiorstwa. Wspieramy firmy we wdrażaniu robotów. Zapraszamy do współpracy!"/>
        <link rel="canonical" href="https://amniscode.pl/production-automation"/>
        <meta name="robots" content="index, follow"/>
    </Helmet>
      <div className="production_cont1">
        <div className="page-title-content__wrapper">
            <div className="title-container">
              <h1 className="title">Automatyzacja procesów produkcyjnych</h1>
              <ProgressLine backgroundColor="#CACFD4" visualParts={[{ percentage: "50%", color: "#69afea" }]}/>
              <h3 className="subtitle">Wspieramy firmy we wdrażaniu robotów</h3>
            </div>
        </div>
      </div>

      <div className="content__wrapper rpa_cont2">
        <h2 className="blue-bold__subtitle">Automatyzacja procesów produkcyjnych</h2>
        <div className="sm-blue-line"></div>
        <div className="d-flex-sp-btwn">
          <div className="txt-sm txt-container">W obecnych czasach <b>automatyzacja procesów produkcyjnych</b> przynosi wiele korzyści dla firmy, 
          a ponadto staje się powoli elementem niezbędnym na produkcji, sprawiając tym samym, że coraz częściej człowiek zastępowany jest przez roboty. 
          Dzięki automatyzacji możliwe jest zwiększenie wydajności produkcji, osiąganie lepszych wyników i oferowanie klientom produktu wyższej jakości. 
          W wielu przypadkach zautomatyzowanie poszczególnych procesów w firmach produkcyjnych staje się już standardem.</div>
          <div className="txt-sm txt-container">Dlatego tak ważne jest stałe monitorowanie rynku i dostępnych rozwiązań technologicznych. 
          Automatyzacja warunkuje przede wszystkim rozwój i możliwość utrzymania pozycji przedsiębiorstwa na rynku. Warto więc rozważyć wprowadzenie 
          automatyzacji produkcji w określonym zakresie w swojej firmie. Mamy wieloletnie doświadczenie w oferowaniu Klientom sprawdzonych rozwiązań 
          związanych z wdrożeniem robotyzacji. Nasi specjaliści wspierają firmy w <b>automatyzacji procesów produkcyjnych</b> na każdym etapie, jako priorytet 
          stawiając przede wszystkim chęć usprawnienia wszelkich działań i podniesienie jakości produkcji.</div>
        </div>
        <div className="d-flex-sp-center">
          <a className="btn-orange mt-45" href="https://amniscode.pl/#kontakt">Porozmawiajmy o automatyzacji w Twojej firmie - napisz do nas</a>
          </div>
      </div>

      <div className="content__wrapper rpa_cont4">
        <h2 className="blue-bold__subtitle el-center">Jakie znaczenie ma automatyzacja i robotyzacja procesów produkcyjnych?</h2>
        <div className="sm-blue-line el-center"></div>
          <div className="txt-sm txt-blue w-75 el-center">Automatyzacja dużych, średnich, jak i małych przedsiębiorstw, które chcą utrzymać swoją pozycję na rynku, 
          ma przede wszystkim usprawnić szereg procesów. Jej podstawowym celem jest zastąpienie człowieka poprzez roboty wysokiej jakości. 
          Połączenie działań człowieka i maszyny umożliwia zwiększenie zysków w firmie. Wpływ na to ma chociażby fakt, iż robot nie potrzebuje przerwy 
          w trakcie wykonywanej pracy. Dzięki temu jest znacznie wydajniejszy od człowieka. Mimo tego, iż koszt inwestycji w zautomatyzowanie produkcji 
          może na początku wydawać się bardzo duży, zwraca się w krótkim czasie, bowiem produkty, które wykonane są przy pomocy maszyn, 
          niemal natychmiast gotowe są do sprzedaży. Jeśli rozważają Państwo tego rodzaju inwestycję, zapraszamy do kontaktu. 
          Dzięki zdobytemu doświadczeniu w automatyzacji przedsiębiorstw reprezentujących różne branże jesteśmy w stanie dobrać najkorzystniejsze rozwiązanie.</div>

        <TrackVisibility once offset={-1}>
          <AutomationSteps/>
        </TrackVisibility>
      </div>

      <div className="content__wrapper rpa_cont2">
        <h2 className="blue-bold__subtitle el-center">Automatyzacja procesów produkcyjnych - dlaczego warto się na nią zdecydować?</h2>
        <div className="sm-blue-line el-center"></div>
        <div className="d-flex-sp-btwn automation_cont">
            <TrackVisibility once offset={150}>
              <AutomationTrack1/>
            </TrackVisibility>
            <TrackVisibility once offset={150}>
              <AutomationTrack2/>
            </TrackVisibility>
        </div>
      </div>

      <div className="rpa_cont6">
        <div className="rpa-ask-for-contact__cont" style={{backgroundImage: `url(${UIbg})` }}>
          <h2 className="rpa-ask-for-contact__title">Gotowy do automatyzacji procesów produkcyjnych?</h2>
          <a className="btn-orange mt-45" href="https://amniscode.pl/#kontakt">{languageData.askForContact}</a>
        </div>
      </div>

      <div className="content__wrapper rpa_cont4">
        <h2 className="blue-bold__subtitle el-center">Automatyzacja procesów produkcyjnych</h2>
        <div className="sm-blue-line el-center"></div>
        <div className="txt-sm txt-blue w-75 el-center">Dopasowanie odpowiedniego rozwiązania związanego z wdrożeniem automatyzacji w firmie powinno być poprzedzone 
        konsultacjami specjalistów oraz osób odpowiedzialnych za cały proces po stronie Klienta. Tylko w taki sposób jesteśmy w stanie dopasować indywidualne rozwiązanie.
         Warto sobie uświadomić, że <b>automatyzacja procesów produkcyjnych</b> jest złożonym przedsięwzięciem. Jako specjaliści z wieloletnim doświadczeniem na rynku, 
         w toku realizacji kolejnych zadań związanych z automatyzacją przedsiębiorstw, wypracowaliśmy skuteczny schemat składający się z pięciu kroków. 
         Rozpoczynamy od przeprowadzenia wnikliwych analiz oraz przygotowania niezbędnej dokumentacji, natomiast kończymy wdrożeniem robotów na produkcję.
          Ponieważ zdajemy sobie sprawę, że w czasie stopniowego wdrażania mogą wystąpić problemy, na każdym z etapów dokładnie analizujemy zaprojektowane rozwiązanie. </div>

          <div className="d-flex-sp-center mt-50">
          <div className="info-box shdw-sm">
            <p className="txt-blue txt-bold txt-procces">Wyższa jakość produktów</p>
            <img src={processQuality} alt="analytics icon"/>
          </div>
          <div className="info-box shdw-sm">
            <p className="txt-blue txt-bold txt-procces">Wzrost przychodów</p>
            <img src={processGrowth} alt="ai icon"/>
          </div>
          <div className="info-box shdw-sm txt-procces">
            <p className="txt-blue txt-bold txt-procces">Poszerzenie oferty</p>
            <img src={processOffer} alt="network icon"/>
          </div>
          <div className="info-box shdw-sm txt-procces">
            <p className="txt-blue txt-bold txt-procces">Podniesienie jakości usług</p>
            <img src={processQualityService} alt="ai icon"/>
          </div>
          <div className="info-box shdw-sm txt-procces">
            <p className="txt-blue txt-bold txt-procces">Zwiększenie konkurencyjności</p>
            <img src={processMarket} alt="ai icon"/>
          </div>
        </div>
      </div>

      <div className="content__wrapper rpa_cont2">
        <h2 className="blue-bold__subtitle el-center">Robotyzacja a zorientowanie na klienta</h2>
        <div className="sm-blue-line el-center"></div>
        <div className="d-flex-sp-btwn automation_cont">
            <TrackVisibility once offset={150}>
              <AutomationTrack3/>
            </TrackVisibility>
            <TrackVisibility once offset={150}>
              <AutomationTrack4/>
            </TrackVisibility>
        </div>
      </div>

      <div className="content__wrapper rpa_cont4">
        <h2 className="blue-bold__subtitle el-center">W jaki sposób pomagamy zautomatyzować procesy na produkcji?</h2>
        <div className="sm-blue-line el-center"></div>
        <div className="txt-sm txt-blue w-75 el-center">Proces wdrożenia automatyzacji w firmie wymaga specjalistycznej wiedzy. 
        Nasze wsparcie dla przedsiębiorcy zaczyna się już na etapie podjęcia decyzji i co najważniejsze, jest kompleksowe. 
        Specjaliści precyzyjnie wyszukują optymalne technologie, które będą idealnie dopasowane do profilu działalności firmy. 
        Przy oferowanym wsparciu podniesienie efektywności produkcji, jak i osiągnięcie celu transformacji jest możliwe do zdobycia już w krótkim czasie. .</div>
        <TrackVisibility once >
          <AutomationTrack5/>
        </TrackVisibility>
      </div>

  </div>
  )
}

export default AutomationProduction;