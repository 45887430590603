import React from 'react';
import {useSelector} from 'react-redux';
import { Helmet } from "react-helmet";

const NewsletterStatue = () => {

    const languageData = useSelector(state => state.languageReducer.data);

    return(
    <>
    <Helmet>
        <link rel="canonical" href="https://amniscode.pl/newsletter-regulations"/>
        <meta name="robots" content="noindex"/>
    </Helmet>
    <section>
        <div id="policy" className="privacy__header--bg"></div>
        <h2 className="privacy__chapter--title">{languageData.NewsletterStatue}</h2>
        <div className="privacy__chapter">
            <h3>§ 1. {languageData.NewsletterStatue1}</h3>
            <ul>              
                <li>{languageData.NewsletterStatue11}</li>
                <li>{languageData.NewsletterStatue12}</li>
                <li>{languageData.NewsletterStatue13}</li>
            </ul>
        </div>

        <div className="privacy__chapter">
        <h3>§ 2. {languageData.NewsletterStatue2}</h3>
        <ul style={{ listStyle:'none'}}>
                <li>{languageData.NewsletterStatue21}
                    <ul>
                        <li>{languageData.NewsletterStatue21a}</li>
                        <li>{languageData.NewsletterStatue21b}</li>
                        <li>{languageData.NewsletterStatue21c}</li>
                        <li>{languageData.NewsletterStatue21d}</li>
                        <li>{languageData.NewsletterStatue21e}</li>
                        <li>{languageData.NewsletterStatue21f}</li>
                    </ul>
                </li>
            </ul>
        </div>

        <div className="privacy__chapter">
        <h3>§ 3. {languageData.NewsletterStatue3}</h3>
            <ul>
            <li>{languageData.NewsletterStatue31}</li>
                <li>{languageData.NewsletterStatue32}</li>
            </ul>
                
          
        </div>

        <div className="privacy__chapter">
        <h3>§ 4. {languageData.NewsletterStatue4}</h3>
        <ul>
                <li>{languageData.NewsletterStatue41}
                    <ul>
                    <li>{languageData.NewsletterStatue41a}</li>
                    <li>{languageData.NewsletterStatue41b}</li>
                        
                        </ul>
                </li>
                <li>{languageData.NewsletterStatue42}</li>
                <li>{languageData.NewsletterStatue43}</li>
            </ul>
        </div>

        <div className="privacy__chapter">
        <h3>§ 5. {languageData.NewsletterStatue5}</h3>
        <ul>
                <li>{languageData.NewsletterStatue51}</li>
                <li>{languageData.NewsletterStatue52}</li>
                <li>{languageData.NewsletterStatue53}
                    <ul>
                        <li>{languageData.NewsletterStatue53a}</li>
                        <li>{languageData.NewsletterStatue53b}</li>
                    </ul>
                </li>
                <li>{languageData.NewsletterStatue54}</li>
            </ul>
        </div>

        <div className="privacy__chapter">
        <h3>§ 6. {languageData.NewsletterStatue6}</h3>
        <ul>
                <li>{languageData.NewsletterStatue61}</li>
                <li>{languageData.NewsletterStatue62}</li>
            </ul>
        </div>

        <div className="privacy__chapter">
        <h3>§ 7. {languageData.NewsletterStatue7}</h3>
        <ul>
                <li>{languageData.NewsletterStatue71} </li>
            </ul>
        </div>

        <div className="privacy__chapter">
        <h3>§ 8. {languageData.NewsletterStatue8}</h3>
        <ul>
                <li> {languageData.NewsletterStatue81}</li>
                <li> {languageData.NewsletterStatue82}
                    <ul>
                        <li>{languageData.NewsletterStatue82a}</li>
                        <li>{languageData.NewsletterStatue82b}</li>
                        <li>{languageData.NewsletterStatue82c}</li>
                        <li>{languageData.NewsletterStatue82d}</li>
                        <li>{languageData.NewsletterStatue82e}</li>
                    </ul>
                </li>
                <li> {languageData.NewsletterStatue83}
                <ul>
                        <li>{languageData.NewsletterStatue83a}</li>
                    </ul>
                </li>
                <li> {languageData.NewsletterStatue84}</li>
                <li> {languageData.NewsletterStatue85}</li>
                <li> {languageData.NewsletterStatue86}</li>
                <li> {languageData.NewsletterStatue87}</li>
                <li> {languageData.NewsletterStatue88}</li>
                <li> {languageData.NewsletterStatue89}</li>
                <li> {languageData.NewsletterStatue810}</li>
                <li> {languageData.NewsletterStatue811}</li>
                <li> {languageData.NewsletterStatue812}</li>
            </ul>
        </div>

        <div className="privacy__chapter">
        <h3>§ 9. {languageData.NewsletterStatue9}</h3>
        <ul>
                <li>{languageData.NewsletterStatue91}</li>
                <li>{languageData.NewsletterStatue92}</li>
                <li>{languageData.NewsletterStatue93}</li>
                <li>{languageData.NewsletterStatue94}</li>
            </ul>
        </div>

        <div className="privacy__chapter">
        <h3>§ 10. {languageData.NewsletterStatue10}</h3>
        <ul>
                <li>{languageData.NewsletterStatue101}</li>
                <li>{languageData.NewsletterStatue102}</li>
            </ul>
        </div>

    </section>

    </>
    );
}

export default NewsletterStatue;