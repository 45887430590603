import React from 'react';
import "animate.css/animate.min.css";
import FirstSectionOld from './FirstSection';
import SecondSectionOld from './SecondSection';
import FindOutSectionOld from './FindOutSection';
import FourthSectionOld from './FourthSection';
import { Helmet } from "react-helmet";

const AboutOld = () => {

    return(
    <>
    <Helmet>
        <title>O nas - AmnisCode</title>
        <meta name="title" content="O nas - AmnisCode"/>
        <meta name="description" content="Zapoznaj się z naszą historią i dowiedz się, co nas wyróżnia na tle innych firm na rynku. Zapraszamy!"/>
        <link rel="canonical" href="https://amniscode.pl/about"/>
        <meta name="robots" content="index, follow"/>
    </Helmet>
    <FirstSectionOld />
    <SecondSectionOld/>
    <FindOutSectionOld/>
    <FourthSectionOld/>
    </>)
}

export default AboutOld;
