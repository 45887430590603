import React from 'react';
import {useSelector} from 'react-redux';
import UIbg from '../../../assets/Uibg1.jpg';
import UiPartner from '../../../assets/UiPartner.png';
import Abbyy from '../../../assets/abbyy.png';
import Saio from '../../../assets/icons/logo_saio.png';

import { Spring } from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";

const LastSection = (props) => {

    const languageData = useSelector(state => state.languageReducer.data);

    function goContact() {
      setTimeout(() => {
      document.getElementById('kontakt').scrollIntoView({behavior: 'smooth'});
    }, 500);
    }

    return(
      <div id="partners" className="home-partners__cont" style={{backgroundImage: `url(${UIbg})` }}>
        <h2 className="home-partners__title">partnerzy</h2>
        <div className="partners-logo__wrapper">
          <img src={UiPartner} className="ui-partner" alt="UiPartner"/>
          {/* <img src={Abbyy} className="abbyy" alt="Abbyy"/> */}
          <img src={Saio} className="saio" alt="saio"/>
        </div>
      </div>
    )

    // return(
    //     <>
       
    //    <div id="partnerstwo" className="bg_Second" style={{backgroundImage: `url(${UIbg})` }}>
    //    <div  className="ui__cont section">
    //    <VisibilitySensor>
    //       {({ isVisible }) => (
    //         <Spring delay={50} to={{ opacity: isVisible ? 1 : 0 }}>
    //           {({ opacity }) => (
    //         <img src={UiPartner} alt="UiPartner" className="ui__cont--logo" style={{opacity }}/>
    //         )}
    //         </Spring>
    //       )}
    //     </VisibilitySensor>
    //         <div className="ui__cont--change">
    //         <VisibilitySensor partialVisibility offset={{ right: -50 }}>
    //       {({ isVisible }) => (
    //           <>
    //         <Spring
    //           config={{duration: 500}}
    //           delay={300}
    //           to={{
    //             opacity: isVisible ? 1 : 0,
    //             transform: isVisible
    //               ? "translateX(0)"
    //               : "translateX(50px)",
    //           }}
    //         >
    //              {(props) => (
                    
    //         <h2 className="ui__cont--title" style={{ ...props }}>
    //             {languageData.UiTitle}
    //         </h2>
    //         )}
                    
    //                 </Spring>
    //         {/* <Spring
    //           delay={600}
    //           to={{
    //             opacity: isVisible ? 1 : 0,
    //             transform: isVisible
    //               ? "translateX(0)"
    //               : "translateX(50px)",
    //           }}
    //         >
    //              {(props) => (
                     
    //         <h2 className="ui__cont--title" style={{ ...props }}>
    //             {languageData.UiSubtitle}
    //         </h2>
            
    //         )}
                    
    //         </Spring> */}
    //         </>  )}
    //      </VisibilitySensor>

    //      <VisibilitySensor partialVisibility offset={{ right: -50 }}>
    //       {({ isVisible }) => (
    //           <>
    //         <Spring
    //           config={{duration: 500}}
    //           delay={300}
    //           to={{
    //             opacity: isVisible ? 1 : 0,
    //             transform: isVisible
    //               ? "translateX(0)"
    //               : "translateX(50px)",
    //           }}
    //         >
    //              {(props) => (
                    
    //         <p className="ui__const--description" style={{ ...props }}>{languageData.UiDescription} </p>
    //         )}
                    
    //         </Spring>
    //         <Spring
    //          config={{duration: 500}}
    //           delay={300}
    //           to={{
    //             opacity: isVisible ? 1 : 0,
    //             transform: isVisible
    //               ? "translateX(0)"
    //               : "translateX(50px)",
    //           }}
    //         >
    //              {(props) => (
                   
    //         <p className="ui__const--description" style={{ ...props }}>{languageData.UiList}</p>
    //         )}
                    
    //         </Spring>
    //         <Spring
    //           config={{duration: 500}}
    //           delay={300}
    //           to={{
    //             opacity: isVisible ? 1 : 0,
    //             transform: isVisible
    //               ? "translateX(0)"
    //               : "translateX(50px)",
    //           }}
    //         >
    //              {(props) => (
                   
    //         <ul style={{ ...props }}>
    //         <li className="ui__const--description"> {languageData.UiListItem1} </li>
    //         <li className="ui__const--description"> {languageData.UiListItem2} </li>
           
    //         </ul>
    //          )}
                    
    //          </Spring>
    //         </>  
    //         )}
    //      </VisibilitySensor>
    //      <VisibilitySensor partialVisibility offset={{ right: -50 }}>
    //       {({ isVisible }) => (
    //           <>
    //         <Spring
    //           config={{duration: 500}}
    //           delay={300}
    //           to={{
    //             opacity: isVisible ? 1 : 0,
    //             transform: isVisible
    //               ? "translateX(0)"
    //               : "translateX(50px)",
    //           }}
              
    //         >
    //              {(props) => (
                    
    //         <p className="ui__const--description" style={{ ...props }}>{languageData.UiDescription2}</p>
    //         )}
                    
    //         </Spring>
    //         <Spring
    //           config={{duration: 500}}
    //           delay={300}
    //           to={{
    //             opacity: isVisible ? 1 : 0,
    //             transform: isVisible
    //               ? "translateX(0)"
    //               : "translateX(50px)",
    //           }}
    //         >
    //              {(props) => (
                   
    //         <p className="ui__const--description" style={{ ...props }}>{languageData.UiDescription3}</p>
    //         )}
                    
    //         </Spring>
    //        </>  
    //        )}
    //     </VisibilitySensor>
            
           
    //                   <a onClick={goContact}   target="_blank" >
    //                     <button className="contact__const--btn">{languageData.FindOutMore}</button>
    //                   </a>
                   
   
    //         </div>
    //     </div>
    //      </div>
       
    //     </div>
    // )
}

export default LastSection;

