import React from 'react';
import {useSelector} from 'react-redux';
import { Helmet } from "react-helmet";

const Privacy = () => {

    const languageData = useSelector(state => state.languageReducer.data);

    return(
    <>
    <Helmet>
        <link rel="canonical" href="https://amniscode.pl/privacy"/>
        <meta name="robots" content="noindex"/>
    </Helmet>
    <section>
        <div id="policy" className="privacy__header--bg"></div>
        <h2 className="privacy__chapter--title">{languageData.Privacy}</h2>
        <div className="privacy__chapter">
            <h3>§ 1. {languageData.PrivacyTitle1}</h3>
            <ul>
                <li>{languageData.PrivacyInf1}</li>
                <li>{languageData.PrivacyInf2}</li>
                <li>{languageData.PrivacyInf3}</li>
                <li>{languageData.PrivacyInf4} 
                    <ul>
                        <li>{languageData.PrivacyInf4a}</li>
                        <li>{languageData.PrivacyInf4b}</li>
                        <li>{languageData.PrivacyInf4c}</li>
                        <li>{languageData.PrivacyInf4d}</li>
                    </ul>
                </li>
                <li>{languageData.PrivacyInf5}</li>
                <li>{languageData.PrivacyInf6}</li>
            </ul>
        </div>

        <div className="privacy__chapter">
        <h3>§ 2. {languageData.PrivacyTitle2}</h3>
        <ul>
                <li>{languageData.PrivacyType1}</li>
                <li>{languageData.PrivacyType2}</li>
                <li>{languageData.PrivacyType3}</li>
                <li>{languageData.PrivacyType4}</li>
                <li>{languageData.PrivacyType5}</li>
                <li>{languageData.PrivacyType6}</li>
            </ul>
        </div>

        <div className="privacy__chapter">
        <h3>§ 3. {languageData.PrivacyTitle3}</h3>
        <ul>
                <li>{languageData.PrivacyLawBack1}</li>
                <li>{languageData.PrivacyLawBack2}</li>
                <li>{languageData.PrivacyLawBack3}</li>
                <li>{languageData.PrivacyLawBack4}</li>
            </ul>
        </div>

        <div className="privacy__chapter">
        <h3>§ 4. {languageData.PrivacyTitle4}</h3>
        <ul>
                <li>{languageData.PrivacyLawObjection1}</li>
                <li>{languageData.PrivacyLawObjection2}</li>
            </ul>
        </div>

        <div className="privacy__chapter">
        <h3>§ 5. {languageData.PrivacyTitle5}</h3>
        <ul>
                <li>{languageData.PrivacyLawDelete1}</li>
                <li>{languageData.PrivacyLawDelete2}
                    <ul>
                        <li>{languageData.PrivacyLawDelete2a}</li>
                        <li>{languageData.PrivacyLawDelete2b}</li>
                        <li>{languageData.PrivacyLawDelete2c}</li>
                        <li>{languageData.PrivacyLawDelete2d}</li>
                        <li>{languageData.PrivacyLawDelete2e}</li>
                    </ul>
                </li>
            </ul>
        </div>

        <div className="privacy__chapter">
        <h3>§ 6. {languageData.PrivacyTitle6}</h3>
        <ul>
                <li>{languageData.PrivacyLawLimit1}</li>
                <li>{languageData.PrivacyLawLimit2} 
                    <ul>
                        <li>{languageData.PrivacyLawLimit2a}</li>
                        <li>{languageData.PrivacyLawLimit2b}</li>
                        <li>{languageData.PrivacyLawLimit2c}</li>
                        <li>{languageData.PrivacyLawLimit2d}</li>
                    </ul>
                </li>
            </ul>
        </div>

        <div className="privacy__chapter">
        <h3>§ 7. {languageData.PrivacyTitle7}</h3>
        <ul>
                <li>{languageData.PrivacyLawAccess1}  
                    <ul>
                        <li>{languageData.PrivacyLawAccess1a}</li>
                        <li>{languageData.PrivacyLawAccess1b}</li>
                        <li>{languageData.PrivacyLawAccess1c}</li>
                   </ul>
                </li>
            </ul>
        </div>

        <div className="privacy__chapter">
        <h3>§ 8. {languageData.PrivacyTitle8}</h3>
        <ul>
                <li> {languageData.PrivacyLawRef1}</li>
                <li> {languageData.PrivacyLawRef2}</li>
            </ul>
        </div>

        <div className="privacy__chapter">
        <h3>§ 9. {languageData.PrivacyTitle9}</h3>
        <ul>
                <li>{languageData.PrivacyComplaints1}</li>
                <li>{languageData.PrivacyComplaints2}</li>
            </ul>
        </div>

        <div className="privacy__chapter">
        <h3>§ 10. {languageData.PrivacyTitle10}</h3>
        <ul>
                <li>{languageData.PrivacyDataRecipient1}</li>
                <li>{languageData.PrivacyDataRecipient2}</li>
            </ul>
        </div>

        <div className="privacy__chapter">
        <h3>§ 11. {languageData.PrivacyTitle11}</h3>
        <ul>
                <li>{languageData.PrivacyDataStorage1}</li>
<li>{languageData.PrivacyDataStorage2} 
                    <ul>
                        <li>{languageData.PrivacyDataStorage2a}</li>
                        <li>{languageData.PrivacyDataStorage2b}</li>
                        <li>{languageData.PrivacyDataStorage2c}</li>
                   </ul>
                </li>
            </ul>
        </div>
    </section>

    </>
    );
}

export default Privacy;