import React from 'react';
import {useSelector} from 'react-redux';


const FindOutSection = (props) => {


    const languageData = useSelector(state => state.languageReducer.data);


    function goOffer() {
        setTimeout(() => {
        document.getElementById('oferta').scrollIntoView({behavior: 'smooth'});
      }, 500);
      }

    return(<>
        <div id="findOut" className="bg_about home__third--img">
            <div className="home__third--cont third_section">          
                             
                <div className="company-section-container">
                    {/* <h1>30%</h1>
                    <div>
                        <h3>{languageData.Economies1}</h3>    
                        <h3>{languageData.Economies2}</h3>    
                        
                        <a onClick={goOffer}   target="_blank" >
                            <button className="contact__const--btn">{languageData.FindOutMore}</button>
                        </a>
                    </div> */}
                    <h4>Dział Administracji</h4>
                    <div className="cost-reduction-block">
                        <div className="info-square">90%</div>
                        <p>Oszczędności czasu</p>
                    </div>
                    <div className="cost-reduction-block">
                        <div className="info-square">75%</div>
                        <p>Redukcji kosztów</p>
                    </div>
                    <div className="cost-reduction-block">
                        <div className="info-square">ROI</div>
                        <p>2 miesiące od wdrożenia</p>
                    </div>
                </div>
                <div className="company-section-container">
                    <h4>Dział Księgowości</h4>
                    <div className="cost-reduction-block">
                        <div className="info-square">80%</div>
                        <p>Redukcji kosztów</p>
                    </div>
                    <div className="cost-reduction-block">
                        <div className="info-square">90%</div>
                        <p>Faktur dekretowanych samodzielnie przez robota</p>
                    </div>
                    <div className="cost-reduction-block">
                        <div className="info-square">ROI</div>
                        <p>4 miesiące od wdrożenia</p>
                    </div>
                </div>
                <div className="company-section-container">
                    <h4>Dział Logistyki</h4>
                    <div className="cost-reduction-block">
                        <div className="info-square">98%</div>
                        <p>Oszczędności czasu</p>
                    </div>
                    <div className="cost-reduction-block">
                        <div className="info-square">10x</div>
                        <p>Skrócenie czasu zamawiania części</p>
                    </div>
                    <div className="cost-reduction-block">
                        <div className="info-square">ROI</div>
                        <p>3 miesiące od wdrożenia</p>
                    </div>
                </div>
            </div>
        </div>
        
        </>
        
    )
}

export default FindOutSection;

