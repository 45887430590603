import React from 'react';
import FindOutSection from './FindOutSection';
import OfferSection from './OfferSection';
import PartnerSection from './PartnerSection';
import UiSection from './UiSection';
import "animate.css/animate.min.css";
import CounterSection from './CounterSection';
import AboutSection from './AboutSection';
import NewsLetter from './NewsLetter';
import { Helmet } from "react-helmet";

const Home = () => {

    // const dispatch = useDispatch();


    // useEffect(() => {
    //     dispatch(changeLanguage('pl'))
    // },[dispatch])

    return(
    <>
    <Helmet>
        <title>Amniscode - nowoczesnie rozwiązania dla Twojego biznesu!</title>
        <meta name="title" content="Amniscode - nowoczesnie rozwiązania dla Twojego biznesu!"/>
        <meta name="description" content="AmnisCode - strumień nowych technologii. Specjalizujemy się w automatyzacji procesów biznesowych. Jesteśmy stworzeni z pasji do nowych technologii."/>
        <link rel="canonical" href="https://amniscode.pl/"/>
        <meta name="robots" content="index, follow"/>
    </Helmet>
    <CounterSection />
    <AboutSection />
    <FindOutSection />
    {/* <ArticlesSection/> */}
    <OfferSection/>
    {/* <UiSection /> */}
    <PartnerSection />
    <NewsLetter/>
    </>)
}

export default Home;
