const initialState = {
    data: 'languages'
}

const languageReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SOME_ACTION':
            return {...state}

        case'SET_LANGUAGE':

            return{...state,
                data: action.payload
            }


            default:
                return{...state}
    }
}

export default languageReducer;