import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {useSelector} from 'react-redux';
import { Spring } from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      color: '#0c5192',
    },
    '& .MuiInputLabel-root' : {
      color: '#0c5192'
    },
    '& .MuiFormLabel-root': {
      fontWeight:'bold'
    },
    '& .MuiIconButton-label': {
    },
    '& .MuiIconButton-label:hover': {
      color: 'inherit'
    },

    '& .MuiFormControlLabel-root' : {
      background: '#ffffff',
      color: '#0c5192',
      margin: '1rem auto',
      padding: '1rem 1rem 1rem 0',
      textAlign: 'left',
      transition: '0.3s',
      border: '1px solid #0c5192'
    },
    '& .MuiFormControlLabel-root:hover' : {
      background: '#0c5192',
      color:'#ffffff'

    },
    '& .MuiFormControlLabel-root:checked' : {
      background: '#0c5192',
      color:'#ffffff'

    },
    '& .MuiTypography-body1': {
      fontSize:'12px'
    },
    '& .MuiTextField-root': {       
          width: '100%',
          cursor: 'pointer',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid #0c5192',
        borderRadius:'0'
      },
   
    },

  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: 'none',
  },
  header: {
    background: '#0c5192',
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  body: {
    background: 'white',
    padding: '0.5rem 2rem',
    textAlign: 'center'
  },

}
));

const GreenCheckbox = withStyles({
  root: {
    color: 'inherit',
   
  },
  checked: {

  },
})((props) => <Checkbox color="default" {...props} />);


const NewsLetter = () => {

  
    const classes = useStyles();
    const languageData = useSelector(state => state.languageReducer.data);
    let url = window.location.protocol + '//' + window.location.host;

    // const [changeComp, setchangeComp] = useState(false);
    const [showCondition, setShowCondition] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [isActive1, setActive1] = useState("false");
    const [isActive2, setActive2] = useState("false");


    //axios
    //test api : http://10.220.15.40:82/amniscode/api/v1/contact/subscribe
    //prod api: https://amniscode.pl/amniscode/api/v1/contact/subscribe

    const [customerEmail, setCustomerEmail] = useState(
      { name:'', email: '', regulationsConsent: false, marketingConsent: false}
    );

    const handleChange = (event) => {
      const value = 
      event.target.type === "checkbox" ? event.target.checked : event.target.value;
      setCustomerEmail({...customerEmail, [event.target.name]: value});
    };


 
    const handleSubmit = (e) => {
        e.preventDefault()

          axios.post(url + '/amniscode/api/v1/contact/subscribe', customerEmail)
          .then(function (response) {
            setOpen(true);
            setShowCondition(false);
            setCustomerEmail({
              name:'', email: '', regulationsConsent: false, marketingConsent: false
            })
          })
          .catch(function (error) {
            
              console.log(error)
          }) 
        
        
    };

    const handleToggle1 = () => {
      setActive1(!isActive1);
    };
    const handleToggle2 = () => {
      setActive2(!isActive2);
    };


    const handleOpen = () => {       
         setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    return(
        <div className="newsLetter__section" >
            <div  className="Contact__cont--txt">
           
              {/* {!changeComp ? ( */}
                <>
                 <VisibilitySensor partialVisibility offset={{ left: -500 }}>
          {({ isVisible }) => (
              
            <Spring
              config={{duration: 500}}
              delay={300}
              to={{
                opacity: isVisible ? 1 : 0,
                transform: isVisible
                  ? "translateX(0)"
                  : "translateX(-50px)",
              }}
            >
                 {(props) => (
                 
                  <>
                <h2 className="header__txt--blue" style={{ ...props }}>
                {languageData.NewsLetterTitle}
                </h2>
                <h3 className="description__txt--blue" style={{ ...props }}>
                  {languageData.NewsLetterDescribe}
                </h3>
                </>
                 )}
                    
                 </Spring>
                )}
              </VisibilitySensor>
       <form 
          id="contact-form" 
          className={classes.root} 
          onSubmit={handleSubmit}  
          method="POST"  
          autoComplete="off">

          <div className="input__cont">
               

          <TextField 
              required
              variant="outlined"
              
              onChange={handleChange} 
              onClick={() => setShowCondition(true)} 
              type="name" 
              name="name" 
              value={customerEmail.name} 
              label={languageData.Name}
               />
            <TextField 
              required
              variant="outlined"
              onChange={handleChange} 
              onClick={() => setShowCondition(true)} 
              type="email" 
              name="email" 
              value={customerEmail.email} 
              label={languageData.Email}
               />
            <button 
              className="contact__const--btn home-contact-btn" 
              type="submit"
              >{languageData.Send}</button>


            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
              timeout: 500,
              }}
            >
              <Fade in={open}>
                <div className={classes.paperModal}>
                  <div className={classes.header}>
                  <CheckCircleIcon style={{ color: 'white'}} />
                  <CloseIcon 
                  onClick={() => setOpen(false)}
                  style={{ color: 'white', cursor: 'pointer'  }} 
                  />

                  </div>
                  <div className={classes.body}>
                    <p>{languageData.RequestSuccessful}</p>
                  </div>
                  
                </div>
              </Fade>
            </Modal>
            
             </div>

             <Slide direction="up" in={showCondition} mountOnEnter unmountOnExit>
              <Paper >
              <FormControlLabel
                    
                    className={!isActive1 ? "active__terms" : ""}
                    elevation={4} 
                    control={<GreenCheckbox 
                    required={true}
                    checked={customerEmail.regulationsConsent} 
                    onClick={handleToggle1}
                    onChange={handleChange} 
                    name="regulationsConsent" />}
                    label={(
                      <>
                        <span>{languageData.RequiredSubscribe11}{languageData.RequiredSubscribe12}
                        {languageData.RequiredSubscribe13}</span>
                      </>
                    )}
                    />
              </Paper>
            </Slide>
            <Slide direction="up" in={showCondition} mountOnEnter unmountOnExit>
              <Paper >
              <FormControlLabel
                    elevation={4} 
                    className={!isActive2 ? "active__terms" : ""}
                    control={<GreenCheckbox 
                    required={true}
                    checked={customerEmail.marketingConsent} 
                    onChange={handleChange} 
                    onClick={handleToggle2}
                    name="marketingConsent"/>}
                    label={languageData.RequiredSubscribe2}
                    />
              </Paper>
            </Slide>    
          
           </form>
           </>

            </div>

            
        </div>
    )
}



export default NewsLetter
