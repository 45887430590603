import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux'
import {changeLanguage} from '../redux/actions/languageAction';
import { useRoutes, useRedirect, usePath } from "hookrouter";
import routes from "../router";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import "animate.css/animate.min.css";
import NonExistentPage from './NoPageFound';

const Layout = () => {

    const currentPath = usePath();
    useRedirect(currentPath, currentPath.replace(/\/?$/, ''));
    const routeResult = useRoutes(routes);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeLanguage('pl'));
    },[])

    return(

        <div className="App">
            <Header></Header>
            <div style={{paddingTop: 65 + 'px'}}></div>
            {routeResult || <NonExistentPage/>}
            <Footer></Footer>
        </div>
    )
}

export default Layout;

