import React from 'react';
import {useSelector} from 'react-redux';
import RPA from '../../../assets/RPA.png';
import MobileApp from '../../../assets/MobileApp.png';
import MLAI from '../../../assets/MIAL.png';
import API from '../../../assets/API.png';

import { Spring } from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";
const FourthSectionOld = () => {

    const languageData = useSelector(state => state.languageReducer.data);



 
    return(
    
    <div id="oferta" className="functionality__cont section">
        <div className="functionality__cont--interview">
          <h2 className="aboutUs__cont--title">{languageData.OurValues}</h2>
        </div>
        
        <div className="functionality__cont--change">
         
        <VisibilitySensor partialVisibility offset={{ left: -400 }}>
          {({ isVisible }) => (
           
            <Spring
              delay={400}
              to={{
                opacity: isVisible ? 1 : 0,
                transform: isVisible
                  ? "translateX(0)"
                  : "translateX(-400px)",
              }}
            >
                 {(props) => ( 
                     
        <div className="functionality__cont--item" style={{ ...props }}>
            <div className="functionality__cont--item--inside">
            <div >
                <img src={RPA} alt="RPA" className="functionality__cont--img"/>
                <h3 className="functionality__cont--title">{languageData.SuccessTitle}</h3>
                <p className="functionality__cont--description">{languageData.Success}</p>
                <div />
            </div>
        </div>
        </div>

 )}
             
    </Spring>
    )}
</VisibilitySensor>
    <VisibilitySensor partialVisibility offset={{ right: -400 }}>
          {({ isVisible }) => (
    <Spring
              delay={800}
              to={{
                opacity: isVisible ? 1 : 0,
                transform: isVisible
                  ? "translateX(0)"
                  : "translateX(400px)",
              }}
            >
                 {(props) => ( 
                  
        <div className="functionality__cont--item"  style={{ ...props }}>
        <div className="functionality__cont--item--inside">
            <div >
            <img src={MLAI} alt="MLAI" className="functionality__cont--img"/>
            <h3 className="functionality__cont--title">{languageData.TeamWorkTitle}</h3>
            <p className="functionality__cont--description">{languageData.TeamWork}</p>
            </div>
            </div>
        </div>
       )}
       </Spring>
        )}
        </VisibilitySensor>
        <VisibilitySensor partialVisibility offset={{ left: -400 }}>
          {({ isVisible }) => (
           
            <Spring
              delay={400}
              to={{
                opacity: isVisible ? 1 : 0,
                transform: isVisible
                  ? "translateX(0)"
                  : "translateX(-400px)",
              }}
            >
                 {(props) => ( 
                     
        <div className="functionality__cont--item" style={{ ...props }}>
        <div className="functionality__cont--item--inside">
        <div >
        <img src={MobileApp} alt="MobileApp" className="functionality__cont--img"/>
            <h3 className="functionality__cont--title">{languageData.PassionTitle}</h3>
            <p className="functionality__cont--description">{languageData.Passion}</p>

            
          </div>
        </div>
        </div>
        
        )}
             
        </Spring>
        )}
    </VisibilitySensor>
        <VisibilitySensor partialVisibility offset={{ right: -400 }}>
              {({ isVisible }) => (
        <Spring
                  delay={800}
                  to={{
                    opacity: isVisible ? 1 : 0,
                    transform: isVisible
                      ? "translateX(0)"
                      : "translateX(400px)",
                  }}
                >
                     {(props) => ( 
                    
        <div className="functionality__cont--item" style={{ ...props }}>
        <div className="functionality__cont--item--inside">
        <div >
            <img src={API} alt="API" className="functionality__cont--img"/>
    <h3 className="functionality__cont--title">{languageData.InnovationTitle}</h3>
            <p className="functionality__cont--description">{languageData.Innovations}</p>
        </div>
        </div>
        </div>
         )}
             
         </Spring>
         )}
     </VisibilitySensor>
        </div>
    </div>
    
    
  )
}

export default FourthSectionOld;
