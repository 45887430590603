import React from 'react';
import {useSelector} from 'react-redux';
import express from '../../../assets/express-logo.png';
import holding from '../../../assets/h1-logo.png';
import pgd from '../../../assets/pgd-logo.png';
import autopunkt from '../../../assets/autopunkt.png';
import traficar from '../../../assets/Traficar_logo.png';
import westmotor from '../../../assets/westMotor.png';
import allex from '../../../assets/allex.png';
import evp from '../../../assets/evp.png';
import { Spring } from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";

const Carousel = () => {

  const languageData = useSelector(state => state.languageReducer.data);

  return (

    
    <div className="carousel__cont section" >
      <div className="carousel--carousel">
      <VisibilitySensor partialVisibility offset={{ left: -50 }}>
          {({ isVisible }) => (
              
            <Spring
              config={{duration: 500}}
              delay={300}
              to={{
                opacity: isVisible ? 1 : 0,
                transform: isVisible
                  ? "translateX(0)"
                  : "translateX(-50px)",
              }}
            >
                 {(props) => (
                 
      
        <h2 className="aboutUs__cont--title"> {languageData.Trust}</h2>
        )}
                    
        </Spring>
        )}
        </VisibilitySensor>
        <div className="carousel">
  <div className="carousel-content">

    <div className="d-flex">
      <img src={holding} className="carousel-item" alt="holding" onClick={()=> window.open("https://www.holding1.pl/", "_blank")}/>
      <img src={pgd} className="carousel-item" alt="pgd" onClick={()=> window.open("https://www.pgd.pl/", "_blank")}/>
      <img src={autopunkt} className="carousel-item" alt="autopunkt" onClick={()=> window.open("https://www.autopunkt.pl/", "_blank")}/>
      <img src={allex} className="carousel-item" alt="allex" onClick={()=> window.open("https://www.allex.info/", "_blank")}/>
      <img src={evp} className="carousel-item evp-logo" alt="evp" onClick={()=> window.open("https://www.evpoland.pl/", "_blank")}/>
    </div>
  
    <div className="d-flex logos-second-row">
      <img src={traficar} className="carousel-item" alt="traficar" onClick={()=> window.open("https://www.traficar.pl/", "_blank")}/>
      <img src={westmotor} className="carousel-item" alt="westmotor" onClick={()=> window.open("https://www.westmotor.pl/", "_blank")}/>
      <img src={express} className="carousel-item" alt="express" onClick={()=> window.open("https://www.express.pl/pg", "_blank")}/>
    </div>
  
  </div>
  </div>
</div>
    </div>
   
  )
}
export default Carousel