import React, { useState } from 'react';
import axios from 'axios';
import logo from '../../../assets/logo-footer.png';
import { Helmet } from "react-helmet";

const Unsubscribe = () => {



    let search = window.location.search;
    let url = window.location.protocol + '//' + window.location.host;
    let params = new URLSearchParams(search);
    let foo = params.get('email');
    

    const [unsubscribeEmail, setUnsubscribeEmail] = useState(
        { email: foo}
      );

    console.log(url);

    
    axios.post( url + '/amniscode/api/v1/contact/unsubscribe', unsubscribeEmail)
          .then(function (response) {
            console.log("wysylka" + unsubscribeEmail.email);
          })
          .catch(function (error) {
            
              console.log(error)
          }) 
    console.log(unsubscribeEmail.email);
    return(
    <>
    <Helmet>
        <link rel="canonical" href="https://amniscode.pl/unsubscribe"/>
    </Helmet>
        <div id="cookie" className="privacy__header--bg"></div>
        <div className="unsubscribe__cont">
            <div>
                <img className="unsubscribe__cont--img" src={logo} />
                <p style={{marginTop: '2em'}}>Twój adres {foo} został usunięty z naszej listy subskrybentów.</p>
                <p style={{fontWeight: 'bold'}} >Pozdrawiamy</p>
            </div>
            
            <p></p>
        </div>
    </>)
}

export default Unsubscribe;
