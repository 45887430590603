import React from 'react';
import {useSelector} from 'react-redux';
import linkedIn from '../../assets/linkedin.png';
import facebook from '../../assets/facebook.png';
import youtube from '../../assets/youtube.png';
import logo from '../../assets/logo.png';
import location from '../../assets/location.png';
import mail from '../../assets/mail.png';
import phone from '../../assets/phone.png';
import { A } from "hookrouter";




const Footer = () => {

    const languageData = useSelector(state => state.languageReducer.data);
    
    function goOpinionRegulations() {
      setTimeout(() => {
        document.getElementById('policy').scrollIntoView({behavior: 'smooth'});
      }, 500);
    }

    function goPolicy() {
      setTimeout(() => {
        document.getElementById('policy').scrollIntoView({behavior: 'smooth'});
      }, 500);
    }

    function goNews() {
      setTimeout(() => {
        document.getElementById('policy').scrollIntoView({behavior: 'smooth'});
      }, 500);
    }

    function goCookies() {
      setTimeout(() => {
        document.getElementById('cookie').scrollIntoView({behavior: 'smooth'});
      }, 500);
    }

    return(
        <div id="kontakt" className="ContactUs__cont" >

            <div  className="contact__cont--txt">
            
                <div className="contact__info--cont">
                
                
                  <div className="contact__info--cont--img"><img src={logo} className="logo__footer" alt="logo" /></div>
                  <div className="contact__info--cont--date">
                  <div className="date__cont">
                      <div className="icons__footer">
                        <img src={location} className="contact__info--icon" alt="location" />
                        <p>AmnisCode Sp. z o.o. Sp.k.</p>
                        </div>
                        <p>ul. Puszkarska 7F</p>
                        <p>Kraków, 30-644</p>
                        </div>
                  
                  <div className="date__cont">
                      <p>KRS: 0000824461</p>
                      <p> NIP: 679-319-52-36</p>
                      <p>REGON: 385351215</p>
                  </div>
                  <div className="date__cont">
                    <div className="icons__footer" style={{margin:'0 0 0.5em;'}} ><img src={phone} className="contact__info--icon" alt="phone"/><a rel="nofollow" href="tel:+48667071642">Tel. +48 667 071 642</a></div>
                    <div className="icons__footer" ><img src={mail} className="contact__info--icon" alt="mail"/><a rel="nofollow" href="mailto:kontakt@amniscode.pl">kontakt@amniscode.pl</a></div>
                      
                  </div>
                  <div className="date__cont">
                      <img src={facebook} alt="facebook" onClick={()=> window.open("https://www.facebook.com/Amnis-Code-104641501312135", "_blank")}/>
                      <img src={linkedIn} alt="linkedIn" onClick={()=> window.open("https://www.linkedin.com/company/amniscode/", "_blank")}/>
                      <img src={youtube} alt="youtube" onClick={()=> window.open("https://www.youtube.com/channel/UCV4DmDkigcqtpiF51PuuoKg/featured?disable_polymer=true", "_blank")}/>               
                  </div>
                </div>                 
                  </div>                 
                <div className="contact__footer">
                <div>       
                    <A rel="nofollow" onClick={goOpinionRegulations} href="/opinion-regulations"><p>{languageData.opinionRegulations69}</p></A>            
                    <A rel="nofollow" onClick={goNews} href="/newsletter-regulations"><p>Regulamin Newsletter</p></A>            
                    <A rel="nofollow" onClick={goPolicy} href="/privacy"><p>{languageData.Privacy}</p></A>
                    <A rel="nofollow" onClick={goCookies} href="/cookies"><p>{languageData.Cookies}</p></A>
                  </div>
                  <p>Copyright 2023 AmnisCode</p>
                  
                </div>

            </div>          
        </div>
    )
}



export default Footer;
