// import React from 'react';
import React, { Component } from "react";
import ProgressLine from "../Home/ProgressLine";
import UIbg from '../../../assets/Uibg1.jpg';
import mobileIcon1 from '../../../assets/icons/mobile11.png';
import mobileIcon2 from '../../../assets/icons/mobile12.png';
import mobileIcon3 from '../../../assets/icons/mobile13.png';
import mobileIcon4 from '../../../assets/icons/mobile14.png';
import mobileIcon5 from '../../../assets/icons/strategy1.svg';

import monitorIcon from '../../../assets/icons/monitor1.png';
import smartphoneIcon from '../../../assets/icons/smartphone1.svg';
import integrationIcon from '../../../assets/icons/integration2.png';
import auditIcon from '../../../assets/icons/audit-process1.png';
import codeIcon from '../../../assets/icons/code1.png';
import networkBg from '../../../assets/bgImg/network_bg1.png';

import uiIcon from '../../../assets/icons/ui_zoom.png';
import uxIcon from '../../../assets/icons/ux1.png';
import webDevIcon from '../../../assets/icons/web_dev1.png';
import rocketIcon from '../../../assets/icons/rocket1.png';
import shieldIcon from '../../../assets/icons/shield1.png';
import supportIcon from '../../../assets/icons/technical_support1.png';
import vectorIcon from '../../../assets/icons/vector1.png';

import bootstrapIcon from '../../../assets/icons/tech_icons/bootstrap-logo.svg';
import angularIcon from '../../../assets/icons/tech_icons/angular.svg';
import reactIcon from '../../../assets/icons/tech_icons/react-icon.svg';
import jsIcon from '../../../assets/icons/tech_icons/js_logo.svg';
import sassIcon from '../../../assets/icons/tech_icons/sass.svg';
import ajaxIcon from '../../../assets/icons/tech_icons/ajax-logo.svg';
import jqueryIcon from '../../../assets/icons/tech_icons/jquery.svg';
import cssIcon from '../../../assets/icons/tech_icons/CSS3_logo.svg';
import linuxIcon from '../../../assets/icons/tech_icons/linux.svg';
import wildflyIcon from '../../../assets/icons/tech_icons/wildfly.svg';
import nginxIcon from '../../../assets/icons/tech_icons/nginx_logo.svg';
import postgresqlIcon from '../../../assets/icons/tech_icons/postgresql-logo.svg';

import arrowIcon from '../../../assets/icons/arrow-left.svg';
import Slider from "react-slick";
import { Helmet } from "react-helmet";

export default class Mobile extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {

    const sliderSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1500,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    

    return (
    <div>
      <Helmet>
        <title>Aplikacje mobilne - AmnisCode</title>
        <meta name="title" content="Aplikacje mobilne - AmnisCode"/>
        <meta name="description" content="Rozwijaj swój biznes dzięki nowoczesnym aplikacjom mobilnym!  Zwiększ efektywność firmy i komunikację z klientami dzięki naszym rozwiązaniom. Zapraszamy!"/>
        <link rel="canonical" href="https://amniscode.pl/mobile"/>
        <meta name="robots" content="index, follow"/>
      </Helmet>
      <div className="mobile_cont1">
        <div className="page-title-content__wrapper">
            <div className="title-container">
              <h1 className="title">Nasze usługi Mobile</h1>
              <ProgressLine backgroundColor="#CACFD4" visualParts={[{ percentage: "23%", color: "#69afea" }]}/>
              <h3 className="subtitle">Zdynamizuj swój biznes</h3>
              <h3 className="subtitle">użytecznymi aplikacjami mobilnymi</h3>
            </div>
        </div>
      </div>

      <div className="content__wrapper mobile_cont2">
        <div className="d-flex-sp-btwn mt-50">
          <div className="title-box">
            <h2 className="blue-bold__subtitle">Przeniesiemy Twój biznes tam, gdzie są Twoi klienci – do sfery mobile</h2>
            <div className="sm-blue-line"></div>
            <p className="txt-sm">W dobie digitalizacji usług oraz coraz częstszego wykorzystania w życiu codziennym sztucznej inteligencji oraz elementów robotyzacji, użytkownicy segmentu IT wysoko stawiają poprzeczkę firmom tworzącym oprogramowanie. Jako Dział Mobile spółki łączymy wieloletnie doświadczenie w branży ze świeżością nowej firmy na rynku polskich startupów. Dzięki dynamicznemu i solidnemu zespołowi developerów, mamy okazję pracować w oparciu o najnowsze technologie, które wiodą prym w trendach rynkowych.</p>
          </div>
          <div className="info-box shdw-sm">
            <img src={mobileIcon1} alt="team icon"/>
            <p className="txt-blue">Mamy <span className="txt-bold">dynamiczny, solidny, komplementarny zespół</span></p>
          </div>
          <div className="info-box shdw-sm">
            <img src={mobileIcon2} alt="settings icon"/>
            <p className="txt-blue">Korzystamy z mocy <span className="txt-bold">najnowszych technologii</span></p>
          </div>
          <div className="info-box shdw-sm">
            <img src={mobileIcon3} alt="quality icon"/>
            <p className="txt-blue">Stawiamy na <span className="txt-bold">jakość i zadowolenie klienta</span></p>
          </div>
          <div className="info-box shdw-sm">
            <img src={mobileIcon4} alt="time icon"/>
            <p className="txt-blue">Posiadamy wieloletnie <span className="txt-bold">doświadczenie</span></p>
          </div>
          <div className="info-box shdw-sm">
            <img src={mobileIcon5} alt="strategy icon"/>
            <p className="txt-blue">Jesteśmy otwarci na <span className="txt-bold">nieszablonowe potrzeby i rozwiązania</span></p>
          </div>
        </div>
      </div>

      <div className="content__wrapper mobile_cont3">
        <h2 className="blue-bold__subtitle el-center">Ulepszymy Twój biznes</h2>
        <div className="sm-blue-line el-center"></div>
        <p className="txt-sm txt-blue w-75 el-center">Twoje produkty będą funkcjonalne i atrakcyjne graficznie, bo oparte na najnowszych narzędziach i stworzone przez multidyscyplinarny i megauzdolniony zespół doświadczonych miłośników ułatwiania życia Klientom i podnoszenia biznesu na wyższy poziom – tam gdzie wzrok nie sięga.</p>

        <div className="d-flex-sp-center mt-50">
          <div className="info-box shdw-sm">
            <p className="txt-blue txt-bold">Aplikacje mobilne - Android, iOS, PWA </p>
            <img src={smartphoneIcon} alt="smartphone icon"/>
          </div>
          <div className="info-box shdw-sm">
            <p className="txt-blue txt-bold">Strony webowe</p>
            <img src={monitorIcon} alt="monitor icon"/>
          </div>
          <div className="info-box shdw-sm">
            <p className="txt-blue txt-bold">Integracje oprogramowania</p>
            <img src={integrationIcon} alt="integration icon"/>
          </div>
          <div className="info-box shdw-sm">
            <p className="txt-blue txt-bold">Audyty oprogramowania</p>
            <img src={auditIcon} alt="audit icon"/>
          </div>
          <div className="info-box shdw-sm">
            <p className="txt-blue txt-bold">Rozwój oprogramowania</p>
            <img src={codeIcon} alt="code icon"/>
          </div>
        </div>
      </div>

      <div className="mobile_cont4 content__wrapper" style={{backgroundImage: `url(${networkBg})` }}>
        <div>
          <div className="d-flex-sp-btwn">
            <div className="title-box">
              <h2 className="container-title">Ty mówisz: Potrzeba<br/>My dajemy rozwiązanie</h2>
              <div className="sm-blue-line"></div>
              <p className="txt-sm">Oferujemy kompleksowe wsparcie na każdym etapie planowania projektu - zaczynając od obszaru strategii biznesowej, poprzez realizację prototypu/wizualizacji graficznej oraz architektury systemu, kończąc na testach, wdrożeniu oraz rozwoju. Realizujemy strony internetowe oraz aplikacje mobilne dostępne komercyjnie, jak również aplikacje wspierające i rozwijające procesy biznesowe/back-office’owe wielu firm.</p>
            </div>
            <div className="question-list__container">
              <ul>
                <li>Masz <span className="bold-light-blue">pomysł</span> na nowy projekt?</li>
                <li>Potrzebujesz pomocy w <span className="bold-light-blue">zdefiniowaniu</span> produktu?</li>
                <li>Chciałbyś otrzymać kompleksowy <span className="bold-light-blue">projekt</span> produktu „pod klucz”?</li>
                <li>Potrzebujesz wsparcia przy <span className="bold-light-blue">realizacji</span> i <span className="bold-light-blue">wdrożeniu</span> produktu?</li>
                <li>Twój produkt potrzebuje kontroli jakości wraz z <span className="bold-light-blue">testami</span>?</li>
                <li>Potrzebujesz <span className="bold-light-blue">wsparcia</span> i <span className="bold-light-blue">rozwoju</span> swojego produktu?</li>
                <li>Myślisz o <span className="bold-light-blue">inwentaryzacji</span> istniejących <span className="bold-light-blue">systemów</span> pod kątem architektury?</li>
              </ul>
            </div>
          </div>
          <div className="solution-steps__cont d-flex-sp-btwn mt-35">
            <div className="solution-step">
              <div className="img-container">
                <img src={uiIcon} alt="ui icon"/>
              </div>
              <p>Analiza projektu UI</p>
            </div>
            <div className="vector-container">
              <img src={vectorIcon} alt="vector icon"/>
            </div>

            <div className="solution-step">
              <div className="img-container">
                <img src={uxIcon} alt="ux icon"/>
              </div>
              <p>Projekt produktu UX</p>
            </div>
            <div className="vector-container">
              <img src={vectorIcon} alt="vector icon"/>
            </div>

            <div className="solution-step">
              <div className="img-container">
                <img src={webDevIcon} alt="web dev icon"/>
              </div>
              <p>Development</p>
            </div>
            <div className="vector-container">
              <img src={vectorIcon} alt="vector icon"/>
            </div>

            <div className="solution-step">
              <div className="img-container">
                <img src={rocketIcon} alt="rocket icon"/>
              </div>
              <p>Wdrożenie produktu</p>
            </div>
            <div className="vector-container">
              <img src={vectorIcon} alt="vector icon"/>
            </div>

            <div className="solution-step">
              <div className="img-container">
                <img src={shieldIcon} alt="shield icon"/>
              </div>
              <p>Kontrola jakości z testami</p>
            </div>
            <div className="vector-container">
              <img src={vectorIcon} alt="vector icon"/>
            </div>

            <div className="solution-step">
              <div className="img-container">
                <img src={supportIcon} alt="support icon"/>
              </div>
              <p>Wsparcie i rozwój</p>
            </div>
          </div>
        </div>
      </div>

      <div className="mobile_cont5">
        <h2 className="blue-bold__subtitle el-center">Narzędzia</h2>
        <div className="sm-blue-line el-center"></div>
        <p className="txt-sm txt-blue w-75 el-center txt-bold">Technologie</p>
        <div className="slider-container">
          <Slider ref={c => (this.slider = c)} {...sliderSettings}>
            <div className="slider-img-wrapper">
              <img src={bootstrapIcon} alt="bootstrapIcon"/>
            </div>
            <div className="slider-img-wrapper">
              <img src={angularIcon} alt="angularIcon"/>
            </div>
            <div className="slider-img-wrapper">
              <img src={reactIcon} alt="reactIcon"/>
            </div>
            <div className="slider-img-wrapper">
              <img src={jsIcon} alt="jsIcon"/>
            </div>
            <div className="slider-img-wrapper">
              <img src={sassIcon} alt="sassIcon"/>
            </div>
            <div className="slider-img-wrapper">
              <img src={ajaxIcon} alt="ajaxIcon"/>
            </div>
            <div className="slider-img-wrapper">
              <img src={jqueryIcon} alt="jquryIcon"/>
            </div>
            <div className="slider-img-wrapper">
              <img src={cssIcon} alt="cssIcon"/>
            </div>
            <div className="slider-img-wrapper">
              <img src={linuxIcon} alt="linuxIcon"/>
            </div>
            <div className="slider-img-wrapper">
              <img src={wildflyIcon} alt="wildflyIcon"/>
            </div>
            <div className="slider-img-wrapper">
              <img src={nginxIcon} alt="nginxIcon"/>
            </div>
            <div className="slider-img-wrapper">
              <img src={postgresqlIcon} alt="postgresqlIcon"/>
            </div>
          </Slider>
          <div>
            <div className="slider-arrow slider-prev-arrow" onClick={this.previous}>
              <img src={arrowIcon} alt="left arrow"/>
            </div>
            <div className="slider-arrow slider-next-arrow" onClick={this.next}>
              <img src={arrowIcon} alt="right arrow"/>
            </div>
          </div>
        </div>
      </div>
      
      <div className="mobile_cont6">
        <div className="ask-for-contact__cont" style={{backgroundImage: `url(${UIbg})` }}>
          <h2 className="mobile-ask-for-contact__title">Gotowy do rozpoczęcia podróży mobile?</h2>
          <a className="btn-orange mt-45" href="https://amniscode.pl/#kontakt">Poproś o kontakt</a>
        </div>
      </div>
    </div>
    )
  }
}

// export default Mobile;