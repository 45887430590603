import React, {Component} from 'react';
import Layout from './components/Layout.js'

import {Provider} from 'react-redux';
import store from "./redux/configureStore";


class App extends Component {
	render() {
		return (
			<Provider store={store}>
      			<Layout />
    		</Provider>
		);
	}
}


export default App;



