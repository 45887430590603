import React from 'react';
import {useSelector} from 'react-redux';
import { Helmet } from "react-helmet";

const Cookies = () => {

    const languageData = useSelector(state => state.languageReducer.data);

    return(
    <>
    <Helmet>
        <link rel="canonical" href="https://amniscode.pl/cookies"/>
        <meta name="robots" content="noindex"/>
    </Helmet>
    <section>
        <div id="cookie" className="privacy__header--bg"></div>
        <h2 className="privacy__chapter--title">{languageData.Cookies}</h2>
        
        <div className="cookie__chapter">
            <ul>
                <li>{languageData.CookiesTitle1}</li>
                <li>{languageData.CookiesTitle2}</li>
                <li>{languageData.CookiesTitle3}</li>
                <li>{languageData.CookiesTitle4} 
                    <ul>
                        <li>{languageData.CookiesTitle4a}</li>
                        <li>{languageData.CookiesTitle4b}</li>
                        <li>{languageData.CookiesTitle4c}</li>
                    </ul>
                </li>
                <li>{languageData.CookiesTitle5}</li>
                <li>{languageData.CookiesTitle6}
                    <ul>
                        <li>{languageData.CookiesTitle6a}</li>
                        <li>{languageData.CookiesTitle6b}</li>
                        <li>{languageData.CookiesTitle6c}</li>
                        <li>{languageData.CookiesTitle6d}</li>
                        <li>{languageData.CookiesTitle6e}</li>
                    </ul>
                </li>
                <li>{languageData.CookiesTitle7}</li>
                <li>{languageData.CookiesTitle8}</li>
                <li>{languageData.CookiesTitle8}</li>
            </ul>
        </div>
    </section>

    </>
    );
}

export default Cookies;