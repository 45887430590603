import React from 'react';
import {useSelector} from 'react-redux';
import { Helmet } from "react-helmet";

const OpinionRegulations = () => {

    const languageData = useSelector(state => state.languageReducer.data);
    let attachment = "";
    function openAttachment(attachment) {
        if(languageData.select == 'PL') {
            attachment = "ListaPlatform.pdf";
        } else if(languageData.select == 'EN') {
            attachment = "PlatformsList.pdf";
        }
        window.open(attachment);
    };

    return(
    <>
    <Helmet>
        <link rel="canonical" href="https://amniscode.pl/opinon-regulations"/>
        <meta name="robots" content="noindex"/>
    </Helmet>
    <section>
        <div id="policy" className="privacy__header--bg"></div>
        <h2 className="privacy__chapter--title">{languageData.opinionRegulations}</h2>
        <div className="privacy__chapter">
            <h3>§ 1. {languageData.opinionRegulations1}</h3>
            <ul>              
                <li><span className='opinions-span'>{languageData.opinionRegulations62}</span>{languageData.opinionRegulations2}</li>
                <li><span className='opinions-span'>{languageData.opinionRegulations63}</span>{languageData.opinionRegulations3}</li>
                <li><span className='opinions-span'>{languageData.opinionRegulations64}</span>{languageData.opinionRegulations4}</li>
                <li><span className='opinions-span'>{languageData.opinionRegulations65}</span>{languageData.opinionRegulations5}</li>
                <li><span className='opinions-span'>{languageData.opinionRegulations66}</span>{languageData.opinionRegulations6}</li>
                <li><span className='opinions-span'>{languageData.opinionRegulations67}</span>{languageData.opinionRegulations7}</li>
                <li><span className='opinions-span'>{languageData.opinionRegulations68}</span>{languageData.opinionRegulations8}</li>
            </ul>
        </div>

        <div className="privacy__chapter">
        <h3>§ 2. {languageData.opinionRegulations9}</h3>
            <ul>
                <li>{languageData.opinionRegulations10}</li>
                <li>{languageData.opinionRegulations11}</li>
                <li>{languageData.opinionRegulations12}</li>
                <li>{languageData.opinionRegulations13}</li>
                <li>{languageData.opinionRegulations14}</li>
            </ul>
        </div>

        <div className="privacy__chapter">
            <h3>§ 3. {languageData.opinionRegulations15}</h3>
            <ul>
                <li>{languageData.opinionRegulations16}
                    <ul>
                        <li>{languageData.opinionRegulations17}</li>
                        <li>{languageData.opinionRegulations18}</li>
                        <li>{languageData.opinionRegulations19}</li>
                    </ul>
                </li>
                <li>{languageData.opinionRegulations20}</li>
                <li>{languageData.opinionRegulations21}</li>
                <li>{languageData.opinionRegulations22}
                    <ul>
                        <li>{languageData.opinionRegulations23}</li>
                        <li>{languageData.opinionRegulations24}</li>
                        <li>{languageData.opinionRegulations25}</li>
                    </ul>
                </li>
                <li>{languageData.opinionRegulations26}</li>
                <li>{languageData.opinionRegulations27}</li>
                <li>{languageData.opinionRegulations28}</li>
                <li>{languageData.opinionRegulations29}</li>
            </ul>
        </div>

        <div className="privacy__chapter">
            <h3>§ 4. {languageData.opinionRegulations30}</h3>
            <ul>
                <li>{languageData.opinionRegulations31}</li>
                <li>{languageData.opinionRegulations32}
                    <ul>
                        <li>{languageData.opinionRegulations33}</li>
                        <li>{languageData.opinionRegulations34}</li>
                    </ul>
                </li>
                <li>{languageData.opinionRegulations35}</li>
                <li>{languageData.opinionRegulations36}</li>
            </ul>
        </div>

        <div className="privacy__chapter">
            <h3>§ 5. {languageData.opinionRegulations37}</h3>
            <ul>
                <li>{languageData.opinionRegulations38}</li>
                <li>{languageData.opinionRegulations39}</li>
                <li>{languageData.opinionRegulations40}</li>
            </ul>
        </div>

        <div className="privacy__chapter">
            <h3>§ 6. {languageData.opinionRegulations41}</h3>
            <ul>
                <li>{languageData.opinionRegulations42}</li>
                <li>{languageData.opinionRegulations43}</li>
                <li>{languageData.opinionRegulations44}</li>
                <li>{languageData.opinionRegulations45}</li>
                <li>{languageData.opinionRegulations46}</li>
                <li>{languageData.opinionRegulations47}</li>
                <li>{languageData.opinionRegulations48}</li>
                <li>{languageData.opinionRegulations49}
                    <ul>
                        <li>{languageData.opinionRegulations50}</li>
                        <li>{languageData.opinionRegulations51}</li>
                        <li>{languageData.opinionRegulations52}</li>
                        <li>{languageData.opinionRegulations53}</li>
                        <li>{languageData.opinionRegulations54}</li>
                    </ul>
                </li>
            </ul>
            <p>{languageData.opinionRegulations55}</p>
        </div>

        <div className="privacy__chapter">
            <h3>§ 7. {languageData.opinionRegulations56}</h3>
            <ul>
                <li>{languageData.opinionRegulations57}</li>
                <li>{languageData.opinionRegulations58}</li>
                <li>{languageData.opinionRegulations59}</li>
                <li>{languageData.opinionRegulations60}</li>
            </ul>
        </div>
        <div className="attachment-container">
            <button className="contact__const--btn attachment-btn" onClick={() => openAttachment(attachment)}>{languageData.opinionRegulations61}</button>
        </div>
    </section>

    </>
    );
}

export default OpinionRegulations;